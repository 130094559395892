import React from 'react'

import { Backdrop, Button, Fade, makeStyles, Modal } from '@material-ui/core'

import { ExclamationIcon } from 'components/icons/personalizedIcons'

const useStyles = makeStyles((theme) => ({
  'label.Mui-focused': {
    color: 'green',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '16px',
    width: '90%',
    maxWidth: '600px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  btnBackgroundErrorPriceSmall: {
    padding: '0.5rem 2rem',
    borderRadius: '50px',
    border: '1px solid #6064ff',
    color: '#6064ff',
    fontWeight: '500',
    backgroundColor: '#ffffff',
    '&:hover': {
      color: '#FF40FC',
      border: '1px solid #FF40FC',
      backgroundColor: '#ffffff',
    },
  },
  btnNewProductsmall: {
    padding: '0.5rem 2rem',
    borderRadius: '50px',
    border: 'none',
    backgroundColor: '#5e44ff',
    color: 'white',
    fontWeight: '500',
    '&:hover': {
      fontWeight: 'bolder',
      backgroundColor: '#ef0bef',
      boxShadow: '-7px 7px 0px #000000',
      border: '2px solid #000000',
      borderRadius: '50px',
      opacity: '1',
    },
  },
}))
const DeleteConfirmation = ({ title, message, onConfirm, onClose, isOpen }) => {
  const classes = useStyles()

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={isOpen}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isOpen}>
        <div className={classes.paper}>
          <div style={{ display: 'flex', justifyContent: 'center', margin: '24px 0' }}>
            <ExclamationIcon />
          </div>
          <h2
            id="transition-modal-title"
            style={{ textAlign: 'center', fontSize: '26px', marginBottom: '24px' }}
          >
            {title}
          </h2>
          <p
            style={{
              fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
              textAlign: 'center',
            }}
          >
            {message}
          </p>
          <div style={{ marginTop: '2rem', display: 'flex', justifyContent: 'space-between' }}>
            <Button
              onClick={onClose}
              color="secondary"
              variant="outlined"
              className={classes.btnBackgroundErrorPriceSmall}
            >
              Cancelar
            </Button>
            <Button
              onClick={onConfirm}
              className={classes.btnNewProductsmall}
              color="primary"
              variant="contained"
            >
              Confirmar
            </Button>
          </div>
        </div>
      </Fade>
    </Modal>
  )
}

export default DeleteConfirmation
