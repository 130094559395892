/* eslint-disable no-param-reassign */
/* eslint-disable radix */
/* eslint-disable no-case-declarations */
/* eslint-disable react/prop-types */
import React, { useState } from 'react'

import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  TextField,
  Tooltip,
} from '@material-ui/core'
import { HelpOutline } from '@material-ui/icons'
import { Autocomplete } from '@material-ui/lab'
import PropTypes from 'prop-types'

import useStyles from '../../../products.styles'

import useCustomDialogAlert from 'components/useCustomDialogAlert'
import ValueAssumed from 'components/ValueAssumed'
import { useProduct } from 'contextApi/ProductContext'
import { justNumbersDecimal, onKeyPressJustNumbers, isAlphanumeric } from 'utils/utils'

const DetailVariant = ({
  setVariation,
  variation,
  fieldValidation,
  setFieldValidation,
  calculate,
  handleTypePacking,
  tariff,
  warehouses,
  valueWarehouse,
  setValueWarehouse,
}) => {
  const showDialogAlert = useCustomDialogAlert()
  const { product, isApprove, cities, country } = useProduct()

  const classes = useStyles()
  const [saveAssumedValue, setSaveAssumedValue] = useState(false)
  const [assumedValueHelperText, setAssumedValueHelperText] = useState(false)

  const assumedValue = () => {
    if (variation.assumedValue === '' || parseInt(variation.assumedValue, 10) >= 0) {
      showDialogAlert(true, '¡Advertencia!', `Se cargó el valor asumido correctamente`, 'Cerrar')
      setSaveAssumedValue(true)
    }
  }

  const changeSameDimensions = (e) => {
    if (e.target.checked) {
      setVariation({
        ...variation,
        sameDimensions: true,

        dimensions: {
          ...variation.dimensions,
          height: product.dimensions.height || 0,
          width: product.dimensions.width || 0,
          length: product.dimensions.length || 0,
          weight: product.dimensions.weight || 0,
        },
      })
      setFieldValidation({
        ...fieldValidation,
        weight: false,
        height: false,
        width: false,
        length: false,
      })
    } else {
      setVariation({
        ...variation,
        sameDimensions: false,
        dimensions: {
          ...variation.dimensions,
          height: 0,
          width: 0,
          length: 0,
          weight: 0,
        },
      })
      setFieldValidation({
        ...fieldValidation,
        weight: true,
        height: true,
        width: true,
        length: true,
      })
    }
  }
  const handleChangeDimensionWeight = (event) => {
    if (event.target.value !== '' && Math.sign(parseInt(event.target.value, 10)) !== 0) {
      setFieldValidation({
        ...fieldValidation,
        [event.target.id]: false,
      })
    } else {
      setFieldValidation({
        ...fieldValidation,
        [event.target.id]: true,
      })
    }
    if (isAlphanumeric(event.target.value)) {
      setVariation({
        ...variation,
        dimensions: {
          ...variation.dimensions,
          [event.target.id]: event.target.value,
        },
      })
    }
  }
  const handleChangeDimension = (event) => {
    if (event.target.value !== '' && Math.sign(parseInt(event.target.value, 10)) !== 0) {
      setFieldValidation({
        ...fieldValidation,
        [event.target.id]: false,
      })
    } else {
      setFieldValidation({
        ...fieldValidation,
        [event.target.id]: true,
      })
    }
    if (Math.sign(parseInt(event.target.value, 10)) !== 0) {
      setVariation({
        ...variation,
        dimensions: {
          ...variation.dimensions,
          [event.target.id]: event.target.value,
        },
      })
    }
  }

  const handleChange = (event) => {
    if (event.target.value !== '' && event.target.id === 'stockQuantity') {
      const pater = /^\+?\d+$/
      const result = pater.test(event.target.value)
      if (!result) {
        return true
      }
    }

    let { value } = event.target

    if (event.target.type === 'date') {
      value = new Date(event.target.value)
    } else if (event.target.type === 'checkbox') {
      value = event.target.checked
    }
    if (value !== '' && Math.sign(parseInt(event.target.value, 10)) !== 0) {
      setFieldValidation({
        ...fieldValidation,
        [event.target.id]: false,
      })
    } else {
      setFieldValidation({
        ...fieldValidation,
        [event.target.id]: true,
      })
    }
    if (event.target.id === 'assumedValue') {
      if (event.target.value.length <= 8) {
        setVariation({
          ...variation,
          [event.target.id]: value,
        })
        setAssumedValueHelperText(false)
      } else {
        setAssumedValueHelperText(true)
        return false
      }
    }
    if (Math.sign(parseInt(event.target.value, 10)) !== 0) {
      if (event.target.id === 'quantityPerShoppingCart') {
        variation.quantityPerShoppingCart = value === '' ? null : parseInt(value)
        setVariation({
          ...variation,
          [event.target.id]: value === '' ? null : value,
        })
        return
      }
      if (event.target.id === 'isReconditioned') {
        setVariation({
          ...variation,
          isReconditioned: event.target.checked,
          isUsed: event.target.checked,
        })
      } else {
        setVariation({
          ...variation,
          [event.target.id]: value,
        })
      }
    }
    return true
  }

  const handleChangeAutocomplete = (event, newValue) => {
    if (newValue?.id !== 0) {
      setFieldValidation({
        ...fieldValidation,
        warehouse_id: false,
      })
    } else {
      setFieldValidation({
        ...fieldValidation,
        warehouse_id: true,
      })
    }
    const warehousesObject = [
      {
        warehouseId: `${newValue.id}`,
        warehouseName: `${newValue.title}`,
        warehouseLocation: {
          country:
            country.length > 0
              ? country.find((countryName) => countryName.id === newValue.countryId)?.name
              : '',
          city: cities.length > 0 ? cities.find((city) => city.id === newValue.cityId)?.name : '',
          address: `${newValue.warehouseAddress}`,
          latitude: `${newValue?.latitude || '0'}`,
          longitude: `${newValue?.longitude || '0'}`,
        },
      },
    ]
    setVariation({
      ...variation,
      warehouses: warehousesObject,
    })
  }

  return (
    <Grid
      container
      justifyContent="space-around"
      alignItems="flex-start"
      className={classes.headerDetail}
    >
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={variation.sameDimensions === true}
              onChange={changeSameDimensions}
              id="sameDimensions"
              name="sameDimensions"
            />
          }
          label="Usar las dimensiones del producto padre."
        />
      </FormGroup>
      <Grid
        container
        justifyContent="space-between"
        alignItems="flex-start"
        className={classes.divWarehouseDetailProduct}
      >
        <FormGroup className={classes.formInputInfo}>
          <InputLabel error={!!fieldValidation.height} className={classes.labelSize}>
            Altura(cm)
          </InputLabel>
          <TextField
            fullWidth
            variant="outlined"
            id="height"
            name="height"
            onChange={handleChangeDimension}
            value={variation.dimensions ? variation.dimensions.height : ''}
            error={!!fieldValidation.height}
            disabled={isApprove}
            helperText={fieldValidation?.height ? 'Este campo es obligatorio' : null}
            onKeyPress={onKeyPressJustNumbers}
          />
        </FormGroup>
        <FormGroup className={classes.formInputInfo}>
          <InputLabel className={classes.labelSize} error={!!fieldValidation.width}>
            Ancho(cm)
          </InputLabel>
          <TextField
            fullWidth
            variant="outlined"
            id="width"
            name="width"
            onChange={handleChangeDimension}
            value={variation.dimensions ? variation.dimensions.width : ''}
            error={!!fieldValidation.width}
            disabled={isApprove}
            helperText={fieldValidation?.width ? 'Este campo es obligatorio' : null}
            onKeyPress={onKeyPressJustNumbers}
          />
        </FormGroup>
        <FormGroup className={classes.formInputInfo}>
          <InputLabel className={classes.labelSize} error={!!fieldValidation.length}>
            Largo(cm)
          </InputLabel>
          <TextField
            fullWidth
            variant="outlined"
            id="length"
            name="length"
            onChange={handleChangeDimension}
            value={variation.dimensions ? variation.dimensions.length : ''}
            error={!!fieldValidation.length}
            disabled={isApprove}
            helperText={fieldValidation?.length ? 'Este campo es obligatorio' : null}
            onKeyPress={onKeyPressJustNumbers}
          />
        </FormGroup>
        <FormGroup className={classes.formInputInfo}>
          <InputLabel className={classes.labelSize} error={!!fieldValidation.weight}>
            Peso(kg)
          </InputLabel>
          <TextField
            fullWidth
            variant="outlined"
            id="weight"
            name="weight"
            onChange={handleChangeDimensionWeight}
            value={variation.dimensions ? variation.dimensions.weight : ''}
            disabled={isApprove}
            error={!!fieldValidation.weight}
            helperText={fieldValidation?.weight ? 'Este campo es obligatorio' : null}
            onKeyPress={justNumbersDecimal}
          />
        </FormGroup>
      </Grid>

      <Divider flexItem />

      <FormGroup error={!!fieldValidation.warehouse_id} className={classes.formInput}>
        <InputLabel error={!!fieldValidation.warehouse_id} className={classes.labelSize}>
          Bodega de origen
        </InputLabel>
        <Autocomplete
          options={warehouses}
          getOptionLabel={(option) => (option.title === null ? '' : option.title)}
          className={classes.autocompletePadding}
          value={valueWarehouse}
          disabled={isApprove}
          onChange={(event, newValue) => {
            setValueWarehouse(newValue)
            handleChangeAutocomplete(event, newValue)
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              error={!!fieldValidation.warehouse_id}
              helperText={fieldValidation?.warehouse_id ? 'Este campo es obligatorio' : null}
              margin="normal"
              variant="outlined"
              className={classes.autocompleteDetailProduct}
            />
          )}
        />
      </FormGroup>
      <FormGroup className={classes.formInput}>
        <InputLabel className={classes.labelSize}>Cantidad límite por carro</InputLabel>
        <TextField
          fullWidth
          variant="outlined"
          id="quantityPerShoppingCart"
          name="quantityPerShoppingCart"
          onChange={handleChange}
          value={variation.quantityPerShoppingCart || ''}
          onKeyPress={justNumbersDecimal}
        />
      </FormGroup>
      <Grid
        container
        alignItems="center"
        direction="row"
        className={classes.divWarehouseDetailProduct}
      >
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={variation.checkTransport}
                onChange={handleChange}
                id="checkTransport"
                name="checkedA"
              />
            }
            label="Transportadora Luegopago"
          />
        </FormGroup>
        <Tooltip
          title="Al marcar esta opción, Luegopago se encargará de gestionar el envío, generando costos de envío por la compra del producto. De lo contrario, el vendedor deberá gestionar el envío, asumiendo los gastos correspondientes."
          interactive
          placement="right"
          classes={{
            tooltipPlacementRight: classes.tooltipPlacementRight,
          }}
          arrow
        >
          <IconButton aria-label="help" className={classes.margin} size="small">
            <HelpOutline fontSize="inherit" />
          </IconButton>
        </Tooltip>
      </Grid>
      {variation.checkTransport ? (
        <Button
          variant="contained"
          color="secondary"
          className={classes.btnBackgroundPrimary}
          type="button"
          onClick={handleTypePacking}
          disabled={saveAssumedValue}
        >
          Calcular
        </Button>
      ) : null}
      <Grid
        container
        justifyContent="space-around"
        alignItems="center"
        className={classes.justifyContent}
      >
        {calculate && variation.checkTransport ? (
          <div className={classes.descriptionProduct}>
            <ValueAssumed tariff={tariff} />
          </div>
        ) : null}
        {variation.checkTransport && calculate ? (
          <>
            <FormGroup className={classes.formInput}>
              <InputLabel className={classes.labelSize}>Tipo de empaque </InputLabel>
              <TextField
                disabled
                id="packageType"
                variant="outlined"
                onChange={handleChange}
                value={variation.packageType}
              />
            </FormGroup>
            <FormGroup className={classes.formInput}>
              <InputLabel className={classes.labelSize}>Valor asumido</InputLabel>
              <TextField
                id="assumedValue"
                name="assumedValue"
                variant="outlined"
                helperText={assumedValueHelperText ? 'Solo se permiten 8 dígitos' : null}
                value={variation.assumedValue || 0}
                onChange={handleChange}
                disabled={saveAssumedValue}
                onKeyPress={onKeyPressJustNumbers}
              />
            </FormGroup>
          </>
        ) : null}
        {calculate && variation.checkTransport ? (
          <Button
            variant="contained"
            color="secondary"
            className={classes.btnNewProduct}
            type="button"
            onClick={assumedValue}
            disabled={saveAssumedValue}
          >
            Guardar
          </Button>
        ) : null}
      </Grid>
    </Grid>
  )
}

DetailVariant.propTypes = {
  defaultProps: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleTypePacking: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  tariff: PropTypes.arrayOf(PropTypes.object).isRequired,
  calculate: PropTypes.bool.isRequired,
}

export default DetailVariant
