/* eslint-disable react/prop-types */

import React, { useState, useEffect } from 'react'

import { Divider, Grid, Button } from '@material-ui/core'
import Axios from 'axios'

import useStyles from '../../../products.styles'

import DetailVariant from './DetailVariant'
import InfoBasic from './InfoBasic'

import useCustomDialogAlert from 'components/useCustomDialogAlert'
import { API_PRODUCTS } from 'constants/urls'
import { useProduct } from 'contextApi/ProductContext'

const InfoProduct = ({
  setVariation,
  variation,
  fieldValidation,
  setFieldValidation,
  calculate,
  setIsLoadingBackDrop,
  setCalculate,
  changeTabs,
  onClose,
}) => {
  const showDialogAlert = useCustomDialogAlert()
  const { warehouses } = useProduct()
  const classes = useStyles()
  const [valueWarehouse, setValueWarehouse] = useState({})

  useEffect(() => {
    if (
      variation.warehouses &&
      variation.warehouses.length > 0 &&
      warehouses &&
      warehouses.length > 0
    ) {
      const warehousesFilter = warehouses.find(
        (wre) => variation.warehouses[0].warehouseId == wre.id
      )
      setValueWarehouse(warehousesFilter)
    }
  }, [variation.warehouse_id, warehouses])

  const [tariff, setTariff] = useState([])
  const handleTypePacking = async () => {
    const { dimensions } = variation
    if (!variation.checkTransport) {
      showDialogAlert(
        true,
        '¡Advertencia!',
        `Transportadora a cargo del vendedor debe de estar activo`,
        'Cerrar'
      )
      return false
    }
    if (
      !dimensions ||
      !dimensions.height ||
      !dimensions.width ||
      !dimensions.length ||
      !dimensions.weight ||
      !variation.warehouses ||
      variation.warehouses?.length < 0
    ) {
      showDialogAlert(
        true,
        '¡Advertencia!',
        `Llena todos los campos para hacer el calculo`,
        'Cerrar'
      )
      return false
    }
    if (
      dimensions.height < 0 ||
      dimensions.width < 0 ||
      dimensions.length < 0 ||
      dimensions.weight < 0
    ) {
      showDialogAlert(true, '¡Advertencia!', `No se permiten valores negativos`, 'Cerrar')
      return false
    }
    const data = {
      length: dimensions.length,
      height: dimensions.height,
      width: dimensions.width,
      weight: dimensions.weight,
      wareHouseId: variation.warehouses[0].warehouseId,
    }
    setIsLoadingBackDrop(true)
    try {
      const response = await Axios({
        url: `${API_PRODUCTS}/ShipmentDetails?Length=${data.length}&Height=${data.height}&Width=${data.width}&Weight=${data.weight}&WareHouseId=${data.wareHouseId}`,
        method: 'GET',
      })
      if (response) {
        setIsLoadingBackDrop(false)
      }
      if (response.data.header.code === 200) {
        setVariation({ ...variation, packageType: response.data.data.packageType })
        setTariff(response.data.data.tarifas)
        setCalculate(true)
        return true
      }
    } catch (error) {
      setIsLoadingBackDrop(false)
    }
    return false
  }

  return (
    <>
      <Grid container justify="space-around" alignItems="flex-start">
        <InfoBasic
          setVariation={setVariation}
          variation={variation}
          fieldValidation={fieldValidation}
          setFieldValidation={setFieldValidation}
        />
        <Divider orientation="vertical" flexItem />
        <DetailVariant
          setVariation={setVariation}
          variation={variation}
          fieldValidation={fieldValidation}
          calculate={calculate}
          setFieldValidation={setFieldValidation}
          handleTypePacking={handleTypePacking}
          tariff={tariff}
          warehouses={warehouses}
          valueWarehouse={valueWarehouse}
          setValueWarehouse={setValueWarehouse}
        />
      </Grid>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        className={classes.gridButtonsInfoBasic}
      >
        <Button className={classes.btnBackgroundError} onClick={() => onClose()}>
          Cancelar
        </Button>
        <Button
          className={classes.btnBackgroundPrimary}
          variant="contained"
          color="secondary"
          type="submit"
          onClick={() => changeTabs(1)}
        >
          Continuar
        </Button>
      </Grid>
    </>
  )
}

export default InfoProduct
