/* eslint-disable react/prop-types */
import React, { useState } from 'react'

import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'

import FilterButtons from 'components/FilterButtons'
import SearchBoxFilter from 'components/SearchBoxFilter'
import { routesLink } from 'constants/routesConsts'
import { STORE_STATUS_FILTER, APPROVEMENTS_FILTER } from 'constants/storeConstants'

const useStyles = makeStyles(() => ({
  container: {
    marginBottom: 30,
  },
  listActions: {
    width: 151,
  },
  divActionsAndFilters: {
    display: 'flex',
    gap: '15px',
  },
  divStates: {
    display: 'flex',
    columnGap: '10px',
  },
}))

const FiltersStores = ({ getModels, showApprovements }) => {
  const history = useHistory()
  const classes = useStyles()

  const [avalaibleSetTextValueEmpty, setAvalaibleSetTextValueEmpty] = useState(false)
  const [selectedState, setSelectedState] = useState(0)

  const handlerOnClick = (status) => {
    if (status === APPROVEMENTS_FILTER) {
      history.push(`${routesLink.approvals}`)
      return
    }
    setAvalaibleSetTextValueEmpty(true)
    setSelectedState(status)
    getModels('', '01/01/0001 0:00:00', '01/01/0001 0:00:00', status)
  }

  const handlerSearch = (text, startDate, endDate, isFilterWithDates) => {
    setAvalaibleSetTextValueEmpty(false)
    if (isFilterWithDates) {
      getModels(text, startDate, endDate)
    } else {
      getModels(text, '01/01/0001 0:00:00', '01/01/0001 0:00:00')
    }
  }

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="flex-end"
      className={classes.container}
    >
      <Grid className={classes.divActionsAndFilters}>
        <Grid>
          <Typography variant="subtitle1">Filtrar por:</Typography>
          <Grid className={classes.divStates}>
            {STORE_STATUS_FILTER.map((status) => {
              if (status.id === APPROVEMENTS_FILTER && !showApprovements) {
                return null
              }
              return (
                <FilterButtons
                  key={`${status.id}-${status.name}`}
                  label={status.name}
                  onClick={() => handlerOnClick(status.id)}
                  active={selectedState === status.id}
                />
              )
            })}
          </Grid>
        </Grid>
      </Grid>
      <SearchBoxFilter
        handlerSearch={handlerSearch}
        avalaibleSetTextValueEmpty={avalaibleSetTextValueEmpty}
        setAvalaibleSetTextValueEmpty={setAvalaibleSetTextValueEmpty}
      />
    </Grid>
  )
}

export default FiltersStores
