/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react'

import { Backdrop, Fade, IconButton, Modal } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/CloseRounded'
import CreditCardIcon from '@material-ui/icons/CreditCardRounded'
import moment from 'moment'

import useStyles from './soat.styles'

import {
  SUCESSFUL_PAYMENT,
  FAILED_PAYMENT,
  ERROR_PAYMENT,
  PENDING_PAYMENT,
} from 'constants/appConstants'
import convertToCurrencyCop from 'utils/convertToCurrencyCop'

export const PAYMENT_STATUS = {
  [SUCESSFUL_PAYMENT]: {
    title: 'Exitoso',
    color: '#00bfa5',
  },
  [PENDING_PAYMENT]: {
    title: 'Pendiente',
    color: '#f57c00',
  },
  [ERROR_PAYMENT]: {
    title: 'Error',
    color: '#f44336',
  },
  [FAILED_PAYMENT]: {
    title: 'Fallido',
    color: '#f44336',
  },
}

const SoatPaymentInfo = ({ isOpen, onClose, paymentInfo }) => {
  const classes = useStyles()
  if (!paymentInfo) return null
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={isOpen}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isOpen}>
        <div className={classes.paper}>
          <div className={classes.paymentModalHeader}>
            <div className={classes.paymentModalHeaderContent}>
              <div>
                <CreditCardIcon />
              </div>
              <p>Información del pago: {paymentInfo.soatId}</p>
              <div
                className={[classes.paymentStatusChip, classes.lightBackground].join(' ')}
                style={{
                  color: PAYMENT_STATUS[paymentInfo.overallStatus.id].color,
                }}
              >
                {PAYMENT_STATUS[paymentInfo.overallStatus.id].title}
              </div>
            </div>

            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </div>
          <div className={classes.paymentModalContent}>
            {paymentInfo.paymentsResponses.length > 1 ? (
              <>
                {paymentInfo.paymentsResponses.map((payment, index) => (
                  <div key={payment.internaltransactionid + index}>
                    <div className={classes.sharedPaymentModalContent}>
                      <span className={classes.sharedPaymentModalTitle}>
                        Pago {index === 0 ? 'principal' : 'secundario'}
                      </span>
                      <div
                        className={classes.paymentStatusChip}
                        style={{
                          color: PAYMENT_STATUS[paymentInfo.overallStatus.id].color,
                        }}
                      >
                        {PAYMENT_STATUS[paymentInfo.overallStatus.id].title}
                      </div>
                    </div>
                    <div className={classes.sharedPaymentModalContent}>
                      <div className={classes.sharedPaymentConcepts}>
                        <span>Fecha de pago</span>
                        <span>Pagador</span>
                        <span>Medio de pago</span>
                        <span>Valor pagado</span>
                      </div>
                      <div className={classes.sharedPaymentValues}>
                        <span>{moment(payment.dateCreated).format('LLL')}</span>
                        <span>
                          {payment.userDocument.type}. {payment.userDocument.number}
                        </span>
                        <span>{payment.paymentType.name}</span>
                        <span>$ {convertToCurrencyCop(Number(payment.valueTransaction))}</span>
                      </div>
                    </div>
                    <hr className={classes.divider} />
                  </div>
                ))}
              </>
            ) : (
              <>
                <div className={classes.sharedPaymentModalContent}>
                  <div className={classes.sharedPaymentConcepts}>
                    <span>Fecha de pago</span>
                    <span>Pagador</span>
                    <span>Medio de pago</span>
                  </div>
                  <div className={classes.sharedPaymentValues}>
                    <span>
                      {moment(paymentInfo.paymentsResponses[0].dateCreated).format('LLL')}
                    </span>
                    <span>
                      {paymentInfo.paymentsResponses[0].userDocument.type}.{' '}
                      {paymentInfo.paymentsResponses[0].userDocument.number}
                    </span>
                    <span>{paymentInfo.paymentsResponses[0].paymentType.name}</span>
                  </div>
                </div>
                <hr className={classes.divider} />
              </>
            )}
            <div className={classes.sharedPaymentModalContent}>
              <div className={[classes.sharedPaymentConcepts, classes.textBlack].join(' ')}>
                <span>Valor de póliza</span>
                <span className={classes.grayColor}>Costos adicionales</span>
                <span>Valor total pagado</span>
              </div>
              <div className={classes.sharedPaymentValues}>
                <span>$ {convertToCurrencyCop(paymentInfo.soatValue)}</span>
                <span className={classes.grayColor}>
                  ${' '}
                  {convertToCurrencyCop(paymentInfo.transactionTotalValue - paymentInfo.soatValue)}
                </span>
                <span className={classes.primaryColor}>
                  $ {convertToCurrencyCop(paymentInfo.transactionTotalValue)}
                </span>
              </div>
            </div>
          </div>
          <div className={classes.paymentModalFooter}>
            <span>Internal Transaction Id: </span> <span>{paymentInfo.internaltransactionid}</span>
          </div>
        </div>
      </Fade>
    </Modal>
  )
}

export default SoatPaymentInfo
