export const PENDING_STATUS = 270
export const ENROLLING_STATUS = 271
export const READY_TO_ACTIVATE_STATUS = 272
export const ENROLLED_STATUS = 273

export const ALL_FILTER = 0
export const ACTIVE_FILTER = 1
export const INACTIVE_FILTER = 2
export const PENDING_FILTER = 3
export const ENROLLING_FILTER = 4
export const READY_TO_ACTIVATE_FILTER = 5
export const ENROLLED_FILTER = 6
export const APPROVEMENTS_FILTER = 7

export const STORE_STATUS_FILTER = [
  {
    id: ALL_FILTER,
    name: 'Todos',
  },
  {
    id: ACTIVE_FILTER,
    name: 'Activos',
  },
  {
    id: INACTIVE_FILTER,
    name: 'Inactivos',
  },
  {
    id: PENDING_FILTER,
    name: 'Pendientes',
  },
  {
    id: ENROLLING_FILTER,
    name: 'En inscripción',
  },
  {
    id: READY_TO_ACTIVATE_FILTER,
    name: 'Listo para activar',
  },
  {
    id: ENROLLED_FILTER,
    name: 'Registradas',
  },
  {
    id: APPROVEMENTS_FILTER,
    name: 'Aprobaciones',
  },
]

const STORE_STATUS = [
  {
    id: PENDING_STATUS,
    filterType: 3,
    prevId: 0,
    name: 'Pendiente',
  },
  {
    id: ENROLLING_STATUS,
    filterType: 4,
    prevId: PENDING_STATUS,
    name: 'En proceso de inscripción',
  },
  {
    id: READY_TO_ACTIVATE_STATUS,
    filterType: 5,
    prevId: ENROLLING_STATUS,
    name: 'Listo para activar',
  },
  {
    id: ENROLLED_STATUS,
    filterType: 6,
    prevId: READY_TO_ACTIVATE_STATUS,
    name: 'Enrolado',
  },
]

export default STORE_STATUS
