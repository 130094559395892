import React, { useEffect, useState, useRef } from 'react'

import { Button, Grid, Typography, TextField, InputLabel } from '@material-ui/core'
import axios from 'axios'
import { format } from 'date-fns'
import MUIDataTable from 'mui-datatables'
import { useForm } from 'react-hook-form'

import useStyles from './bonuses.styles'

import BackDrop from 'components/BackDrop'
import useCustomDialogAlert from 'components/useCustomDialogAlert'
import { VOUCHER } from 'constants/urls'
import convertToCurrencyCop from 'utils/convertToCurrencyCop'
import { onKeyPressJustNumbers } from 'utils/utils'

export default function Bonuses() {
  const classes = useStyles()

  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState([])
  const [filters, setFilter] = useState({
    type: 0,
    code: null,
  })

  const { register, handleSubmit, errors } = useForm()

  const showDialogAlert = useCustomDialogAlert()

  const getModels = async (type = 0, code = null) => {
    setIsLoading(true)
    setFilter({
      type: type,
      code: code,
    })
    await axios
      .post(`${VOUCHER}/Filters`, {
        filterType: type,
        filterText: code,
      })
      .then((response) => {
        if (response.status === 200) {
          setData(response.data)
          setIsLoading(false)
        }
      })
      .catch((e) => {
        setIsLoading(false)
        console.log(e)
      })
  }

  const InactivateBonus = async (id) => {
    setIsLoading(true)

    await axios
      .put(`${VOUCHER}/InactivateVoucher/${id}`)
      .then((response) => {
        if (response.status === 200) {
          getModels(filters.type, filters.code)
          setIsLoading(false)
          showDialogAlert(
            true,
            `Bono redimido `,
            `Tu bono ha sido Redimido Exitosamente `,
            'Aceptar'
          )
        }
      })
      .catch((e) => {
        setIsLoading(false)
        console.log(e)
      })
  }

  const redeemBonus = async (id) => {
    showDialogAlert(
      true,
      `Redimir bono`,
      `¿Esta seguro que desea redimir este bono? `,
      'Aceptar',
      'Cancelar',
      false,
      () => InactivateBonus(id)
    )
  }

  const onSearchSubmit = async (dataForm) => {
    if (dataForm?.qr) {
      getModels(2, dataForm?.qr)
    } else if (dataForm?.idDocument) {
      getModels(1, dataForm?.idDocument)
    } else {
      showDialogAlert(true, '¡Advertencia!', 'Debes escanear el codigo QR', 'Cerrar')
    }
  }
  useEffect(() => {
    getModels()
  }, [])

  const columns = [
    {
      name: 'id',
      options: {
        display: false,
        download: false,
      },
    },

    {
      name: 'idDocument',
      label: 'Documento',
    },

    {
      name: 'name',
      label: 'Nombre',
    },
    {
      name: 'email',
      label: 'Correo',
    },
    {
      name: 'value',
      label: 'Valor',
      options: {
        customBodyRender: (value) => {
          return <div>{`$${convertToCurrencyCop(Number(value))}`}</div>
        },
      },
    },

    {
      name: 'createdOn',
      label: 'Fecha creación',
      options: {
        customBodyRender: (value) => format(new Date(value), 'yyyy/MM/dd H:mm'),
      },
    },
    {
      name: 'status',
      label: 'Estado',
      options: {
        customBodyRender: (value) => {
          return <div>{`${value === 0 ? 'Inactivo' : 'Activo'}`}</div>
        },
      },
    },

    {
      name: '',
      label: '',
      options: {
        customBodyRender: (value, tableMeta) =>
          tableMeta.rowData[6] ? (
            <Button
              color="secondary"
              variant="text"
              onClick={() => redeemBonus(tableMeta.rowData[0])}
              className={classes.btnSecondary}
            >
              Redimir
            </Button>
          ) : null,
      },
    },
  ]

  const options = {
    responsive: 'scroll',
    search: false,
    filterType: 'dropdown',
    rowHover: true,
    filter: false,
    sort: false,
    print: false,
    download: false,
    viewColumns: false,
    selectableRows: 'none',
    textLabels: {
      body: {
        noMatch: `${isLoading ? 'Cargando ...' : 'No hay registros'}`,
      },
      pagination: {
        next: 'Página siguiente',
        previous: 'Página anterior',
        rowsPerPage: 'Filas por página:',
        displayRows: 'de',
      },
      toolbar: {
        search: 'Buscar',
        downloadCsv: 'Descargar CSV',
      },
      selectedRows: {
        text: 'Fila(s) Seleccionada(s)',
      },
    },
  }

  return (
    <>
      <BackDrop open={isLoading} />

      <Grid container justify="space-between">
        <Typography variant="h6">Lista de Bonos</Typography>
      </Grid>
      <form onSubmit={handleSubmit(onSearchSubmit)} style={{ marginTop: '2%' }}>
        <Grid container justify="cemter" spacing={2} style={{ padding: '0 20%' }}>
          <Grid item xs={12} md={4}>
            <InputLabel className={classes.topInputLabel}>Busqueda por QR</InputLabel>
            <TextField
              name="qr"
              variant="outlined"
              fullWidth
              inputRef={register()}
              onKeyPress={onKeyPressJustNumbers}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <InputLabel className={classes.topInputLabel} error={errors?.idDocument}>
              Número de documento
            </InputLabel>
            <TextField
              name="idDocument"
              variant="outlined"
              fullWidth
              inputProps={{
                maxLength: 20,
                minLength: 4,
              }}
              error={errors?.idDocument}
              inputRef={register({
                minLength: {
                  value: 4,
                  message: 'Debe de tener un mínimo de 4 digitos.',
                },
                maxLength: {
                  value: 20,
                  message: 'Debe de tener un mínimo de 20 digitos.',
                },
                pattern: {
                  value: /^[a-zA-Z0-9\s#]*$/,
                  message: 'No se permiten caracteres especiales.',
                },
              })}
              helperText={errors?.idDocument?.message}
              onKeyPress={onKeyPressJustNumbers}
            />
          </Grid>
          <Grid item xs={4}>
            <Button
              color="secondary"
              variant="contained"
              type="submit"
              style={{ marginTop: '20px' }}
              className={classes.btnBackgroundPrimary}
            >
              Buscar
            </Button>
          </Grid>
        </Grid>
      </form>

      <Grid container className={classes.divContent}>
        <MUIDataTable data={data} columns={columns} options={options} />
      </Grid>
    </>
  )
}
