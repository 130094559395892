/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'

import {
  Chip,
  Grid,
  IconButton,
  InputBase,
  Paper,
  Switch,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core'
import AssessmentIcon from '@material-ui/icons/AssessmentRounded'
import BuildIcon from '@material-ui/icons/BuildRounded'
import CloseIcon from '@material-ui/icons/CloseRounded'
import FilterListIcon from '@material-ui/icons/FilterListRounded'
import { Skeleton } from '@material-ui/lab'
import axios from 'axios'
import moment from 'moment'
import MUIDataTable from 'mui-datatables'

import FiltersModal from './FiltersModal'
import MaintenanceConfirmation from './MaintenanceConfirmation'
import SoatPaymentInfo from './OrderPaymentInfo'
import useStyles, { CustomSwitchStyles } from './soat.styles'
import SoatFinancialOverview from './SoatFinancialOverview'

import BackDrop from 'components/BackDrop'
import FilterButtons from 'components/FilterButtons'
import { SearchIcon, EyeIcon, DownloadIcon } from 'components/icons/personalizedIcons'
import { MAINTENANCE_MODE_KEY, SOAT_ISSUED_ID } from 'constants/appConstants'
import { SETTING_CONFIGURATION, API_SOAT, API_PAYMENTS } from 'constants/urls'
import getClienIp from 'services/utilityServices'
import convertToCurrencyCop from 'utils/convertToCurrencyCop'
import { formatCammelCaseObject } from 'utils/cryptData'
import { textLabels } from 'utils/functions'

moment.locale('es', {
  months: [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ],
  monthsShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
  weekdays: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
  weekdaysShort: ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb'],
  longDateFormat: {
    LT: 'HH:mm',
    LTS: 'HH:mm:ss',
    L: 'DD/MM/YYYY',
    LL: 'MMMM D, YYYY',
    LLL: 'MMM D, YYYY h:mm:ss A',
    LLLL: 'dddd, D [de] MMMM [de] YYYY h:mm A',
  },
  relativeTime: {
    future: 'en %s',
    past: 'hace %s',
    s: 'unos segundos',
    ss: '%d segundos',
    m: 'un minuto',
    mm: '%d minutos',
    h: 'una hora',
    hh: '%d horas',
    d: 'un día',
    dd: '%d días',
    M: 'un mes',
    MM: '%d meses',
    y: 'un año',
    yy: '%d años',
  },
  // am and pm are lowercase in Spanish
  meridiem: (hour) => {
    if (hour < 12) {
      return 'a.m.'
    }
    return 'p.m.'
  },
})

const CustomSwitch = withStyles((theme) => ({
  ...CustomSwitchStyles(theme),
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  )
})

const setInitialDate = () => {
  const today = new Date()
  const firstDay = new Date(today.getFullYear(), today.getMonth(), 1)
  const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0)
  return {
    from: firstDay,
    to: lastDay,
  }
}

const StringToObjectTranslation = (string) => {
  return JSON.parse(formatCammelCaseObject(JSON.stringify(string)))
}

const SoatOrders = () => {
  const classes = useStyles()
  const [request, setRequest] = useState({
    ...setInitialDate(),
    page: 1,
    size: 10,
    sort: 'createdOn',
    filterBy: 0,
    search: '',
  })
  const [tableMetadata, setTableMetadata] = useState({
    page: 1,
    pageSize: 10,
    total: 0,
    totalPages: 0,
  })
  const [filterText, setFilterText] = useState('')
  const [tableData, setTableData] = useState([])
  const [toogleMaintenance, setToogleMaintenance] = useState(false)
  const [filters, setFilters] = useState([])
  const [clientIp, setClientIp] = useState(null)
  const [soatModals, setSoatModals] = useState({
    filters: false,
    financialOverview: false,
    maintenance: false,
    orderPaymentInfo: false,
  })
  const [isLoading, setIsLoading] = useState(false)
  const [maintenanceInfo, setMaintenanceInfo] = useState(null)
  const [orderPaymentInfo, setOrderPaymentInfo] = useState(false)
  const [financialData, setFinancialData] = useState(null)

  const GetMaintenanceModeState = async () => {
    setIsLoading(true)
    try {
      const response = await axios.get(`${SETTING_CONFIGURATION}/GetSettingsByGroup/20`)
      const maintenanceState = response.data.find((flag) => flag.name === MAINTENANCE_MODE_KEY)
      setMaintenanceInfo(() => maintenanceState)
      setToogleMaintenance(maintenanceState.value === 'true')
      setIsLoading(false)
    } catch (error) {
      console.log(error)
      setIsLoading(false)
    }
  }

  const toogleMaintenanceMode = async (state) => {
    const formDataSetting = new FormData()
    formDataSetting.append(`ID`, maintenanceInfo.id)
    formDataSetting.append(`Name`, maintenanceInfo.name)
    formDataSetting.append(`Value`, state ? 'true' : 'false')
    formDataSetting.append(`SettingGroupId`, maintenanceInfo.settingGroupId)
    try {
      const response = await axios.post(
        `${SETTING_CONFIGURATION}/CreateUpdateSetting`,
        formDataSetting
      )

      setSoatModals((prevState) => ({
        ...prevState,
        maintenance: false,
      }))
      setMaintenanceInfo(() => response.data)
      setToogleMaintenance(response.data.value === 'true')
    } catch (error) {
      console.log(error)
    }
  }

  const GetSoatData = async () => {
    const body = JSON.stringify({
      page: request.page,
      pageSize: request.size,
      search: request.search,
      StatusId: request.filterBy,
      from: request.from,
      to: request.to,
      clientIp,
    })

    try {
      const { data } = await axios.post(`${API_SOAT}/Order/getOrders`, body)
      const formattedData = StringToObjectTranslation(data)
      const paginationInfo = formattedData.page
      setTableData(formattedData.data)
      setFinancialData(formattedData.financialData)
      setTableMetadata({
        page: paginationInfo.page,
        pageSize: paginationInfo.pageSize,
        total: paginationInfo.records,
        totalPages: paginationInfo.totalPages,
      })
    } catch (error) {
      setTableData([])
      setTableMetadata({
        page: 1,
        pageSize: 10,
        total: 0,
        totalPages: 0,
      })
      setFinancialData(null)
      console.log(error)
    }
  }

  const GetSoatFilters = async () => {
    const body = JSON.stringify({
      from: request.from,
      to: request.to,
      clientIp,
    })
    try {
      const { data } = await axios.post(`${API_SOAT}/Order/getFilters`, body)
      const formattedData = StringToObjectTranslation(data)

      if (!Array.isArray(formattedData.data)) {
        setFilters([])
        return
      }
      setFilters(formattedData.data)
    } catch (error) {
      setFilters([])
      console.log(error)
    }
  }

  const GetSoatFile = async (soatId) => {
    setIsLoading(true)
    const body = JSON.stringify({
      soatId,
      clientIp,
    })
    try {
      const { data } = await axios.post(`${API_SOAT}/Soat/getSoatFileById`, body)
      const formattedData = StringToObjectTranslation(data)

      const base64String = `data:application/pdf;base64,${formattedData.base64}`
      const link = document.createElement('a')
      link.href = base64String
      link.download = 'archivo.pdf'
      link.click()
      link.remove()
    } catch (error) {
      console.log(error)
    }
  }

  const GetPaymentInfo = async (initPointId, soatValue, soatId) => {
    try {
      const response = await axios.get(
        `${API_PAYMENTS}/Payments/TransactionDetailsBy?filterBy=InitPointId&search=${initPointId}`
      )

      setOrderPaymentInfo({
        ...response.data.data,
        soatValue,
        soatId,
      })
      setSoatModals((prevState) => ({
        ...prevState,
        orderPaymentInfo: true,
      }))
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    setIsLoading(true)
    getClienIp().then((ip) => {
      setClientIp(ip)
    })
    GetMaintenanceModeState()
  }, [])

  useEffect(() => {
    if (!clientIp) return
    setIsLoading(true)
    GetSoatFilters().then(() => {
      GetSoatData().finally(() => {
        setIsLoading(false)
      })
    })
  }, [request, clientIp])
  return (
    <div>
      <BackDrop open={isLoading} />
      <Grid container spacing={1} justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="h6" className={classes.primaryColor}>
            Ordenes de SOAT
          </Typography>
        </Grid>
        <Grid item>
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <Tooltip
                title="Ventas"
                interactive
                placement="top"
                classes={{
                  tooltipPlacementTop: classes.tooltipPlacementTop,
                }}
              >
                <IconButton
                  className={classes.iconButton}
                  onClick={() => {
                    setSoatModals((prevState) => ({
                      ...prevState,
                      financialOverview: true,
                    }))
                  }}
                >
                  <AssessmentIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip
                title="Filtrar por fecha"
                interactive
                placement="top"
                classes={{
                  tooltipPlacementTop: classes.tooltipPlacementTop,
                }}
              >
                <IconButton
                  className={classes.iconButton}
                  onClick={() => {
                    setSoatModals((prevState) => ({
                      ...prevState,
                      filters: true,
                    }))
                  }}
                >
                  <FilterListIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip
                title="Modo mantenimiento"
                interactive
                placement="top"
                classes={{
                  tooltipPlacementTop: classes.tooltipPlacementTop,
                }}
              >
                <IconButton
                  className={classes.iconButton}
                  onClick={() => {
                    setSoatModals((prevState) => ({
                      ...prevState,
                      maintenance: true,
                    }))
                  }}
                >
                  <BuildIcon />
                  <CustomSwitch
                    checked={toogleMaintenance}
                    onClick={(e) => {
                      e.preventDefault()
                    }}
                  />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <br />

      <Grid container spacing={1} justifyContent="space-between" alignItems="center">
        <Grid item>
          <Grid container spacing={1}>
            {!isLoading ? (
              filters.length > 0 ? (
                filters.map(({ name, id, records }) => (
                  <Grid item key={id}>
                    <FilterButtons
                      label={`${records} ${name}`}
                      disabled={isLoading || records === 0}
                      onClick={() => {
                        setIsLoading(true)
                        setRequest({ ...request, page: 1, pageSize: 10, filterBy: id })
                        setIsLoading(false)
                      }}
                      active={id === request.filterBy}
                    />
                  </Grid>
                ))
              ) : (
                <Grid item>
                  <FilterButtons label="Sin filtros disponibles" disabled />
                </Grid>
              )
            ) : (
              <>
                {Array.from({ length: 6 }, (_, i) => i).map((_, index) => (
                  <Grid key={`skeleton-${index.toString()}`} item>
                    <Skeleton variant="rect" className={classes.skeleton} width={100} height={40} />
                  </Grid>
                ))}
              </>
            )}
          </Grid>
        </Grid>

        <Grid item>
          <Paper className={classes.root}>
            <InputBase
              className={classes.input}
              placeholder="¿Qué estás buscando?"
              value={filterText}
              onChange={(e) => {
                setFilterText(e.target.value)
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  if (filterText === request.search) return
                  setRequest({ ...request, page: 1, pageSize: 10, search: filterText })
                  setFilterText('')
                }
              }}
            />

            {filterText.length > 0 && filterText === request.search ? (
              <IconButton
                className={classes.iconButton}
                aria-label="search"
                onClick={() => {
                  setFilterText('')
                }}
              >
                <CloseIcon />
              </IconButton>
            ) : (
              <IconButton
                className={classes.iconButton}
                aria-label="search"
                onClick={() => {
                  if (filterText === request.search) return
                  setRequest({ ...request, page: 1, pageSize: 10, search: filterText })
                  setFilterText('')
                }}
              >
                <SearchIcon />
              </IconButton>
            )}
            <div className={classes.helperText}>
              <span>
                {`${moment(request.from).format('L')} - ${moment(request.to).format('L')}`}
              </span>
              {request.search.length > 0 && (
                <Chip
                  style={{
                    margin: '0 0.2rem',
                    fontSize: '0.8rem',
                    height: 'auto',
                  }}
                  label={request.search}
                  onDelete={() => {
                    setRequest({ ...request, page: 1, pageSize: 10, search: '' })
                  }}
                />
              )}
            </div>
          </Paper>
        </Grid>
      </Grid>

      <MaintenanceConfirmation
        isOpen={soatModals.maintenance}
        isActive={toogleMaintenance}
        onConfirm={() => {
          setIsLoading(true)
          toogleMaintenanceMode(!toogleMaintenance).finally(() => {
            setIsLoading(false)
          })
        }}
        onClose={() => {
          setSoatModals((prevState) => ({
            ...prevState,
            maintenance: false,
          }))
        }}
      />
      <FiltersModal
        isOpen={soatModals.filters}
        initialDateRange={{
          from: request.from,
          to: request.to,
        }}
        onClose={() => {
          setSoatModals((prevState) => ({
            ...prevState,
            filters: false,
          }))
        }}
        onConfirm={({ from, to }) => {
          setRequest((prevState) => ({
            ...prevState,
            from,
            to,
          }))
          setSoatModals((prevState) => ({
            ...prevState,
            filters: false,
          }))
        }}
      />
      <SoatFinancialOverview
        isOpen={soatModals.financialOverview}
        financialData={financialData}
        statusId={request.filterBy}
        onClose={() => {
          setSoatModals((prevState) => ({
            ...prevState,
            financialOverview: false,
          }))
        }}
      />
      <SoatPaymentInfo
        isOpen={soatModals.orderPaymentInfo}
        onClose={() => {
          setSoatModals((prevState) => ({
            ...prevState,
            orderPaymentInfo: false,
          }))
          setOrderPaymentInfo(null)
        }}
        paymentInfo={orderPaymentInfo}
      />
      <br />
      <MUIDataTable
        options={{
          selectableRows: false,
          print: false,
          download: false,
          sort: false,
          page: tableMetadata.page - 1,
          count: tableMetadata.total,
          rowsPerPage: tableMetadata.pageSize,
          rowsPerPageOptions: [10, 25, 50],
          serverSide: true,
          filter: false,
          search: false,
          viewColumns: false,
          textLabels: textLabels(isLoading),
          tableBodyMaxHeight: '70vh',
          tableBodyHeight: '55vh',
          onChangePage: (currentPage) => {
            setRequest((prevState) => ({
              ...prevState,
              page: currentPage + 1,
            }))
          },
          onChangeRowsPerPage: (rows) => {
            setRequest((prevState) => ({
              ...prevState,
              size: rows,
            }))
          },
        }}
        data={tableData}
        columns={[
          {
            name: 'id',
            label: 'ID',
            options: {
              customHeadLabelRender: () => <th className={classes.soatTableHeader}>ID</th>,
            },
          },
          {
            name: 'documentType',
            options: {
              display: false,
            },
          },
          {
            name: 'documentNumber',
            options: {
              display: false,
            },
          },
          {
            name: '',
            label: 'DOCUMENTO',
            options: {
              customHeadLabelRender: () => <th className={classes.soatTableHeader}>DOCUMENTO</th>,
              customBodyRender: (value, tableMeta) => {
                const documentType = tableMeta.rowData[1]
                const documentNumber = tableMeta.rowData[2]
                return `${documentType} - ${documentNumber}`
              },
            },
          },
          {
            name: 'firstName',
            options: {
              display: false,
            },
          },
          {
            name: 'lastName',
            options: {
              display: false,
            },
          },
          {
            name: '',
            label: 'PROPIETARIO',
            options: {
              customHeadLabelRender: () => <th className={classes.soatTableHeader}>PROPIETARIO</th>,
              customBodyRender: (value, tableMeta) => {
                const firstName = tableMeta.rowData[4]
                const lastName = tableMeta.rowData[5]
                return `${firstName} ${lastName}`
              },
            },
          },
          {
            name: 'licensePlate',
            label: 'PLACA',
            options: {
              customHeadLabelRender: () => <th className={classes.soatTableHeader}>PLACA</th>,
            },
          },
          {
            name: 'amount',
            options: {
              display: false,
            },
          },
          {
            name: 'feeValue',
            options: {
              display: false,
              // customBodyRender: (value) => `$ ${convertToCurrencyCop(Number(value))}`,
            },
          },
          {
            name: 'paymentMethod',
            label: 'MÉTODO DE PAGO',
            options: {
              customHeadLabelRender: () => (
                <th className={classes.soatTableHeader}>MÉTODO DE PAGO</th>
              ),
              customBodyRender: (value) => {
                if (value === 'Luegopago') {
                  return 'No realizado'
                }
                if (value) {
                  return value
                }
                return 'No disponible'
              },
            },
          },
          {
            name: '',
            label: 'VALOR PAGADO',
            options: {
              customHeadLabelRender: () => (
                <th className={classes.soatTableHeader}>VALOR PAGADO</th>
              ),
              customBodyRender: (_, tableMeta) => {
                const amount = tableMeta.rowData[8]
                const fee = tableMeta.rowData[9]
                const operationText = `$ ${convertToCurrencyCop(
                  Number(amount)
                )} + $ ${convertToCurrencyCop(Number(fee))}`
                return (
                  <Tooltip
                    title={
                      <div>
                        <span>Valor de la póliza + Tarifa de servicio</span>
                        <br />
                        <span>{operationText}</span>
                      </div>
                    }
                    interactive
                    placement="top"
                    classes={{
                      tooltipPlacementTop: classes.tooltipPlacementTop,
                    }}
                  >
                    <span>{`$ ${convertToCurrencyCop(Number(amount) + Number(fee))}`}</span>
                  </Tooltip>
                )
              },
            },
          },
          {
            name: 'statusName',
            label: 'ESTADO',
            options: {
              customHeadLabelRender: () => <th className={classes.soatTableHeader}>ESTADO</th>,
            },
          },
          {
            name: 'createdOn',
            label: 'FECHA DE CREACIÓN',
            options: {
              customHeadLabelRender: () => (
                <th className={classes.soatTableHeader}>FECHA DE CREACIÓN</th>
              ),
              customBodyRender: (value) => {
                const date = new Date(value)
                return (
                  <Tooltip
                    title={moment(date).format('LLLL')}
                    interactive
                    placement="top"
                    classes={{
                      tooltipPlacementTop: classes.tooltipPlacementTop,
                    }}
                  >
                    <span>{moment(date).fromNow()}</span>
                  </Tooltip>
                )
              },
            },
          },
          {
            name: 'statusId',
            options: {
              display: false,
            },
          },
          {
            name: 'soatId',
            options: {
              customHeadLabelRender: () => <th className={classes.soatTableHeader}>SOAT ID</th>,
              customBodyRender: (value) => {
                return value !== 0 ? value : 'No disponible'
              },
            },
          },
          {
            name: 'paymentTransactionId',
            options: {
              display: false,
            },
          },
          {
            name: '',
            label: 'ACCIONES',
            options: {
              customHeadLabelRender: () => <th className={classes.soatTableHeader}>ACCIONES</th>,
              customBodyRender: (_, tableMeta) => {
                const statusId = tableMeta.rowData[14]
                const orderId = tableMeta.rowData[0]
                const soatId = tableMeta.rowData[15]
                const initPointId = tableMeta.rowData[16]
                const soatValue = tableMeta.rowData[8]
                const isPaymentDone = tableMeta.rowData[10] !== 'Luegopago'
                if (!isPaymentDone) return null
                return (
                  <Grid
                    container
                    direction="row"
                    wrap="nowrap"
                    justifyContent="center"
                    alignItems="center"
                    spacing={1}
                  >
                    <Grid item>
                      <Tooltip
                        title="Ver"
                        interactive
                        placement="top"
                        classes={{
                          tooltipPlacementTop: classes.tooltipPlacementTop,
                        }}
                      >
                        <IconButton
                          className={classes.iconEye}
                          onClick={() => {
                            setIsLoading(true)
                            GetPaymentInfo(initPointId, soatValue, orderId).finally(() => {
                              setIsLoading(false)
                            })
                          }}
                        >
                          <EyeIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                    {statusId === SOAT_ISSUED_ID && (
                      <Grid item>
                        <Tooltip title="Descargar">
                          <IconButton
                            className={classes.iconDownload}
                            onClick={() => {
                              setIsLoading(true)
                              GetSoatFile(soatId).finally(() => {
                                setIsLoading(false)
                              })
                            }}
                          >
                            <DownloadIcon />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    )}
                  </Grid>
                )
              },
            },
          },
        ]}
      />
    </div>
  )
}

export default SoatOrders
