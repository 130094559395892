/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'

import {
  TextField,
  FormGroup,
  Grid,
  InputLabel,
  FormControlLabel,
  Checkbox,
  Button,
  Typography,
  Divider,
  Select,
  FormControl,
  InputAdornment,
  Tooltip,
  IconButton,
} from '@material-ui/core'
import { HelpOutline } from '@material-ui/icons'
import Autocomplete from '@material-ui/lab/Autocomplete'
import PropTypes from 'prop-types'

import useStyles from '../../products.styles'

import useCustomDialogAlert from 'components/useCustomDialogAlert'
import ValueAssumed from 'components/ValueAssumed'
import { useProduct } from 'contextApi/ProductContext'
import {
  onKeyPressJustNumbers,
  isAlphanumeric,
  justNumbersDecimal,
  removeLeadingZero,
  formatNumber,
} from 'utils/utils'

const DetailProduct = ({
  defaultProps,
  handleTypePacking,
  loading,
  tariff,
  calculate,
  setCalculate,
  valueWarehouse,
  setValueWarehouse,
  register,
  errors,
  warrantyOption,
  setWarrantyOption,
  clearErrors,
}) => {
  const classes = useStyles()
  const { product, setProduct, isApprove, cities, country } = useProduct()
  const [saveAssumedValue, setSaveAssumedValue] = useState(false)
  const [assumedValueHelperText, setAssumedValueHelperText] = useState(false)

  const showDialogAlert = useCustomDialogAlert()
  const handleChangeDimension = (event) => {
    const valorId = event.target.id
    if (
      valorId === 'height' ||
      valorId === 'width' ||
      valorId === 'length' ||
      valorId === 'weight'
    ) {
      setCalculate(false)
      setSaveAssumedValue(false)
      setProduct({
        ...product,
        assumedValue: '0',
      })
    }
    if (isAlphanumeric(event.target.value))
      setProduct({
        ...product,
        dimensions: {
          ...product.dimensions,
          [event.target.id]: event.target.value,
        },
      })
  }

  const handleChange = (event) => {
    const { id, value, type, checked } = event.target
    switch (id) {
      case 'stockQuantity':
        if (value !== '') {
          const pater = /^\+?\d+$/
          const newvalue = removeLeadingZero(value.replace(/,/g, ''))
          if (!pater.test(newvalue)) {
            break
          }
          clearErrors('stockQuantity')
          setProduct({
            ...product,
            [id]: formatNumber(newvalue),
          })
        } else {
          setProduct({
            ...product,
            [id]: 0,
          })
        }
        break
      case 'isReconditioned':
        setProduct({
          ...product,
          isReconditioned: checked,
          isUsed: checked,
        })
        break
      case 'assumedValue':
        if (value.length > 8) {
          setAssumedValueHelperText(true)
          break
        }
        setAssumedValueHelperText(false)
        setProduct({
          ...product,
          [id]: value,
        })
        break
      default:
        if (type === 'date') {
          const dateValue = new Date(value)
          setProduct({
            ...product,
            [id]: dateValue,
          })
        } else if (type === 'checkbox') {
          setProduct({
            ...product,
            [id]: checked,
          })
        } else if (isAlphanumeric(value)) {
          setProduct({
            ...product,
            [id]: value,
          })
        }
        break
    }
  }

  const assumedValue = () => {
    if (product.assumedValue === '' || parseInt(product.assumedValue) >= 0) {
      showDialogAlert(
        true,
        '¡Cambio exitoso!',
        `Se cargó el valor asumido correctamente`,
        'Cerrar',
        '',
        true
      )
      setSaveAssumedValue(true)
    }
  }

  const handleChangeAutocomplete = (event, newValue) => {
    const warehouses = [
      {
        warehouseId: `${newValue.id}`,
        warehouseName: `${newValue.title}`,
        warehouseLocation: {
          country:
            country.length > 0
              ? country.find((countryName) => countryName.id === newValue.countryId)?.name
              : '',
          city: cities.length > 0 ? cities.find((city) => city.id === newValue.cityId)?.name : '',
          address: `${newValue.warehouseAddress}`,
          latitude: `${newValue.latitude || '0'}`,
          longitude: `${newValue.longitude || '0'}`,
        },
      },
    ]
    setProduct({
      ...product,
      warehouses: warehouses,
    })
  }

  const optionsWarranty = [
    {
      id: 1,
      name: 'Días',
    },
    {
      id: 2,
      name: 'Meses',
    },
    {
      id: 2,
      name: 'Años',
    },
  ]

  const handleChangeAutocompleteWarranty = (value) => {
    const newValue = value * 1
    const diccionary = {
      Días: 'day',
      Meses: 'month',
      Años: 'year',
    }
    const unitWarranty = diccionary[warrantyOption]
    setProduct({
      ...product,
      warranty: {
        unit: unitWarranty,
        value: newValue,
      },
    })
  }

  const stockNewProduct = () => {
    const value = parseInt(product.stockQuantity.toString().replace(/,/g, ''), 10)
    if (value > 0) return formatNumber(value)
    if (product.type === 'service') return 1000

    if (product.type === 'simple' || product.type === 'virtual') return 0
  }

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      className={classes.headerDetail}
    >
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        className={classes.detailContent}
      >
        <Typography className={classes.detailSend}>Detalles de envío</Typography>
      </Grid>
      <Grid
        container
        justifyContent="space-between"
        alignItems="flex-start"
        className={classes.divWarehouseDetailProduct}
      >
        <Typography className={classes.ProductPartTypo}>Producto 1</Typography>
        <FormGroup className={classes.formInputInfo}>
          <InputLabel className={classes.labelSize} error={!!errors.height}>
            Altura (cm)
          </InputLabel>
          <TextField
            fullWidth
            variant="outlined"
            id="height"
            name="height"
            onChange={handleChangeDimension}
            value={product.dimensions ? product.dimensions.height : ''}
            inputRef={register}
            error={!!errors.height}
            helperText={errors?.height?.message}
            onKeyPress={onKeyPressJustNumbers}
            disabled={isApprove || product.type === 'virtual' || product.type === 'service'}
          />
        </FormGroup>
        <FormGroup className={classes.formInputInfo}>
          <InputLabel className={classes.labelSize} error={!!errors.width}>
            Ancho (cm)
          </InputLabel>
          <TextField
            fullWidth
            variant="outlined"
            id="width"
            name="width"
            onChange={handleChangeDimension}
            value={product.dimensions ? product.dimensions.width : ''}
            inputRef={register}
            error={!!errors.width}
            helperText={errors?.width?.message}
            onKeyPress={onKeyPressJustNumbers}
            disabled={isApprove || product.type === 'virtual' || product.type === 'service'}
          />
        </FormGroup>
        <FormGroup className={classes.formInputInfo}>
          <InputLabel className={classes.labelSize} error={!!errors.length}>
            Largo (cm)
          </InputLabel>
          <TextField
            fullWidth
            variant="outlined"
            id="length"
            name="length"
            onChange={handleChangeDimension}
            value={product.dimensions ? product.dimensions.length : ''}
            inputRef={register}
            error={!!errors.length}
            helperText={errors?.length?.message}
            onKeyPress={onKeyPressJustNumbers}
            disabled={isApprove || product.type === 'virtual' || product.type === 'service'}
          />
        </FormGroup>
        <FormGroup className={classes.formInputInfo}>
          <InputLabel className={classes.labelSize} error={!!errors.weight}>
            Peso (kg)
          </InputLabel>
          <TextField
            fullWidth
            variant="outlined"
            id="weight"
            name="weight"
            onChange={handleChangeDimension}
            value={product.dimensions ? product.dimensions.weight : ''}
            inputRef={register}
            error={!!errors.weight}
            helperText={errors?.weight?.message}
            onKeyPress={justNumbersDecimal}
            disabled={isApprove || product.type === 'virtual' || product.type === 'service'}
          />
        </FormGroup>
        <Divider className={classes.formInputDivider} />
        {product.type === 'variable' ||
          (product.type === 'service' && (
            <FormGroup className={classes.formInput}>
              <InputLabel className={classes.labelSize} error={!!errors.stockQuantity}>
                Cantidad límite por carro
              </InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                id="quantityPerShoppingCart"
                name="quantityPerShoppingCart"
                onChange={handleChange}
                value={product.quantityPerShoppingCart}
                inputRef={register}
                onKeyPress={justNumbersDecimal}
              />
            </FormGroup>
          ))}
        {product.type === 'simple' && (
          <FormGroup className={classes.formInput}>
            <InputLabel error={!!errors.warehouse_id_name} className={classes.labelSize}>
              Bodega de origen
            </InputLabel>
            <Autocomplete
              {...defaultProps}
              id="warehouse_id"
              name="warehouse_id"
              className={classes.autocompletePadding}
              value={valueWarehouse}
              onChange={(event, newValue) => {
                setValueWarehouse(newValue)
                handleChangeAutocomplete(event, newValue)
              }}
              disabled={isApprove || product?.type === 'virtual' || product?.type === 'service'}
              renderInput={(params) => (
                <TextField
                  id="warehouse_id_name"
                  name="warehouse_id_name"
                  {...params}
                  inputRef={register}
                  error={!!errors.warehouse_id_name}
                  helperText={errors?.warehouse_id_name?.message}
                  label=""
                  margin="normal"
                  variant="outlined"
                  className={classes.autocompleteDetailProduct}
                />
              )}
            />
          </FormGroup>
        )}
        {(product.type === 'variable' || product.type === 'simple') && (
          <>
            <FormGroup className={classes.formInput}>
              <InputLabel className={classes.labelSize}>Garantia</InputLabel>
              <FormControl variant="outlined" className={classes.autocompletePadding}>
                <InputLabel htmlFor="outlined-age-native-simple">Por</InputLabel>
                <Select
                  native
                  value={warrantyOption}
                  onChange={(event) => {
                    const diccionary = {
                      Días: 'day',
                      Meses: 'month',
                      Años: 'year',
                    }
                    setProduct({
                      ...product,
                      warranty: {
                        unit: diccionary[event.target.value],
                        value: product.warranty.value,
                      },
                    })
                    setWarrantyOption(event.target.value)
                  }}
                  label="Por"
                  inputProps={{
                    name: 'Por',
                    id: 'outlined-age-native-simple',
                  }}
                >
                  <option aria-label="None" value="" />
                  {optionsWarranty.map(({ id, name }) => (
                    <option key={id}>{name}</option>
                  ))}
                </Select>
              </FormControl>
            </FormGroup>
            {warrantyOption !== '' && (
              <FormGroup className={classes.formInput}>
                <InputLabel className={classes.labelSize}>
                  {`Cantidad Garantia en ${warrantyOption}`}
                </InputLabel>
                <TextField
                  fullWidth
                  variant="outlined"
                  onChange={(e) => handleChangeAutocompleteWarranty(e.target.value)}
                  value={product.warranty.value}
                  onKeyPress={justNumbersDecimal}
                />
              </FormGroup>
            )}
          </>
        )}
        {(product.type === 'simple' || product.type === 'virtual') && (
          <>
            <FormGroup className={classes.formInput}>
              <InputLabel className={classes.labelSize} error={!!errors.stockQuantity}>
                Número de existencias
              </InputLabel>
              <TextField
                fullWidth
                id="stockQuantity"
                name="stockQuantity"
                variant="outlined"
                onKeyPress={onKeyPressJustNumbers}
                onChange={handleChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" variant="outlined">
                      #
                    </InputAdornment>
                  ),
                }}
                value={stockNewProduct()}
                inputRef={register}
                error={!!errors.stockQuantity}
                helperText={errors?.stockQuantity?.message}
                disabled={isApprove || product?.type === 'service'}
              />
            </FormGroup>
            {product.type !== 'virtual' && <div className={classes.formInput} />}
            <FormGroup className={classes.formInput}>
              <InputLabel className={classes.labelSize}>Cantidad límite por carro</InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                id="quantityPerShoppingCart"
                name="quantityPerShoppingCart"
                onChange={handleChange}
                value={product.quantityPerShoppingCart}
                inputRef={register}
                onKeyPress={justNumbersDecimal}
              />
            </FormGroup>
          </>
        )}
      </Grid>
      {product.type === 'simple' && (
        <>
          <Grid
            container
            direction="row"
            alignItems="center"
            className={classes.divWarehouseDetailProduct}
          >
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={
                      isApprove || product?.type === 'virtual' || product?.type === 'service'
                    }
                    checked={product.checkTransport}
                    onChange={handleChange}
                    id="checkTransport"
                    name="checkedA"
                  />
                }
                label="Transportadora Luegopago"
              />
            </FormGroup>
            <Tooltip
              title="Al marcar esta opción, Luegopago se encargará de gestionar el envío, generando costos de envío por la compra del producto. De lo contrario, el vendedor deberá gestionar el envío, asumiendo los gastos correspondientes."
              interactive
              placement="right"
              classes={{
                tooltipPlacementRight: classes.tooltipPlacementRight,
              }}
              arrow
            >
              <IconButton aria-label="help" className={classes.margin} size="small">
                <HelpOutline fontSize="inherit" />
              </IconButton>
            </Tooltip>
          </Grid>
          {product.checkTransport || isApprove ? (
            <FormGroup className={classes.formInput}>
              <Button
                variant="contained"
                color="secondary"
                className={classes.btnBackgroundPrimary}
                type="button"
                onClick={handleTypePacking}
                disabled={
                  saveAssumedValue ||
                  loading ||
                  product.type === 'virtual' ||
                  product.type === 'service'
                }
              >
                Calcular
              </Button>
            </FormGroup>
          ) : null}
          <Grid
            container
            justifyContent="space-around"
            alignItems="center"
            className={classes.justifyContent}
          >
            {calculate && product.checkTransport ? (
              <div className={classes.descriptionProduct}>
                <ValueAssumed tariff={tariff} />
              </div>
            ) : null}
            {product.checkTransport && calculate ? (
              <>
                <FormGroup className={classes.formInput}>
                  <InputLabel className={classes.labelSize}>Tipo de empaque </InputLabel>
                  <TextField
                    disabled
                    id="packageType"
                    variant="outlined"
                    onChange={handleChange}
                    value={product.packageType}
                  />
                </FormGroup>
                <FormGroup className={classes.formInput}>
                  <InputLabel className={classes.labelSize}>Valor asumido</InputLabel>
                  <TextField
                    id="assumedValue"
                    name="assumedValue"
                    variant="outlined"
                    value={product.assumedValue}
                    helperText={assumedValueHelperText ? 'Solo se permiten 8 dígitos' : null}
                    onChange={handleChange}
                    disabled={saveAssumedValue}
                    onKeyPress={onKeyPressJustNumbers}
                  />
                </FormGroup>
              </>
            ) : null}
            {calculate && product.checkTransport ? (
              <Button
                variant="contained"
                color="secondary"
                className={classes.btnNewProduct}
                type="button"
                onClick={assumedValue}
                disabled={saveAssumedValue}
              >
                Guardar
              </Button>
            ) : null}
          </Grid>
        </>
      )}
    </Grid>
  )
}

DetailProduct.propTypes = {
  defaultProps: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleTypePacking: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  tariff: PropTypes.arrayOf(PropTypes.object).isRequired,
  calculate: PropTypes.bool.isRequired,
}

export default DetailProduct
