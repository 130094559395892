export const MAINTENANCE_MODE_KEY = 'SoatUnderConstruction'

export const ALL_SOAT_STATUS_ID = 0
export const SOAT_PAYMENT_PENDING_ID = 10
export const SOAT_PAYMENT_FAILED_ID = 11
export const SOAT_PAYMENT_SUCCESSFUL_ID = 12
export const SOAT_ISSUED_ID = 13
export const NOT_ISSUED_STATUS_ID = 14

export const SUCESSFUL_PAYMENT = 1
export const PENDING_PAYMENT = 2
export const ERROR_PAYMENT = 3
export const FAILED_PAYMENT = 4

export const DEFAULT_IP = '1.1.1.1'
