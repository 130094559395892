/* eslint-disable import/prefer-default-export */
import axios from 'axios'

import { API_SELLERS } from 'constants/urls'

export const getSellersServices = async (total = 30000) => {
  const result = await axios.get(`${API_SELLERS}?sellerFilterType=1&pageSize=${total}`)
  return result
}

export const getEnlistedSellers = async ({
  searchText,
  startDate,
  endDate,
  pageNumber,
  sellerFilterType,
  pageSize,
}) => {
  const queryParams = new URLSearchParams({
    searchText,
    startDate,
    endDate,
    pageNumber,
    sellerFilterType,
    pageSize,
  })
  const response = await axios.get(`${API_SELLERS}/EnrolledByUser?${queryParams}`)
  return response
}

export const registerSeller = async (seller) => {
  const response = await axios.post(`${API_SELLERS}/Enroll`, seller, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
  return response
}

export const changeStoreStatus = async (sellerId, currentStatus, newStatus) => {
  const response = await axios.post(`${API_SELLERS}/ChangeStatus`, {
    sellerId,
    currentStatus,
    newStatus,
  })
  return response
}
