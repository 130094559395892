import React, { useState } from 'react'

import {
  Grid,
  Button,
  FormControlLabel,
  Checkbox,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  FormGroup,
} from '@material-ui/core'
import { useHistory } from 'react-router-dom'

import Certificates from '../components/Certificates'
import styles from '../generalConfig.styles'

import useCustomDialogAlert from 'components/useCustomDialogAlert'
import { routesLink } from 'constants/routesConsts'
import { PENDING_STATUS } from 'constants/storeConstants'

const LegalDocuments = ({
  data,
  setData,
  handleBack,
  onSubmitData,
  setErrorsCertificates,
  errorsCertificates,
  isAdmin,
  isCommercial,
}) => {
  const classes = styles()
  const history = useHistory()
  const showDialogAlert = useCustomDialogAlert()
  const [openModalDeclaration, setOpenModalDeclaration] = useState(false)
  const [checkDeclaration, setCheckDeclaration] = useState(false)
  const onSubmit = () => {
    if (isAdmin) {
      onSubmitData()
    } else if (
      !data?.dataCertificate?.fileRut ||
      (data.personalData?.typeOfPersonId === 19 &&
        !data?.dataCertificate?.fileCommercialRegister) ||
      !data?.dataCertificate?.fileLegRepDocumentCopy ||
      !data?.dataCertificate?.fileBankCertificate
    ) {
      setErrorsCertificates(
        data?.personalData?.typeOfPersonId === 18
          ? {
              fileRut: !data?.dataCertificate?.fileRut || true,
              fileLegRepDocumentCopy: !data?.dataCertificate?.fileLegRepDocumentCopy || true,
              fileBankCertificate: !data?.dataCertificate?.fileBankCertificate || true,
            }
          : {
              fileRut: !data?.dataCertificate?.fileRut || true,
              fileCommercialRegister: !data?.dataCertificate?.fileCommercialRegister || true,
              fileLegRepDocumentCopy: !data?.dataCertificate?.fileLegRepDocumentCopy || true,
              fileBankCertificate: !data?.dataCertificate?.fileBankCertificate || true,
            }
      )
      showDialogAlert(true, '¡Advertencia!', `Los archivos son obligatorios.`, 'Cerrar')
    } else {
      onSubmitData()
    }
  }
  const closeModalDeclaration = () => {
    setOpenModalDeclaration(false)
  }
  const handleChangeDeclaration = (event) => {
    setCheckDeclaration(event.target.checked)
  }
  const handleChange = () => {
    setOpenModalDeclaration(true)
  }
  return (
    <>
      <Dialog
        disableEscapeKeyDown
        fullWidth
        maxWidth="xl"
        classes={{ paperWidthXl: classes.modalVariationForm }}
        onClose={closeModalDeclaration}
        open={openModalDeclaration}
      >
        <DialogTitle className={classes.title} disableTypography>
          <Typography variant="h6" style={{ textAlign: 'center' }}>
            Declaración de fondos
          </Typography>
        </DialogTitle>
        <DialogContent className={classes.variantContent}>
          <Grid container alignItems="center" style={{ marginTop: 10 }}>
            <Typography variant="paragraph">
              Declaro de manera voluntaria y dando certeza de que lo aquí consignado es información
              veraz y verificable, lo siguiente : (I) Que los ingresos y los recursos utilizados en
              la relación comercial no proviene de ninguna actividad ilícita de las contempladas en
              el código penal colombiano o en cualquier otra norma que lo modifique o adicione, ni
              serán utilizados para efectos de financiación de actividades terroristas o cualquier
              otra conducta delictiva, de acuerdo con las normas penales vigentes en Colombia, (II)
              Que los socios y administradores de la sociedad no han sido incluidos en listas de
              control de riesgo de lavado de activos y financiación del terrorismo nacionales o
              internacionales, entre las que se encuentran la lista de la Oficina de Control de
              Activos en el Exterior-OFAC emitida por la oficina del tesoro de los estados Unidos de
              América y la lista de sanciones del Consejo de Seguridad de la organización de las
              Naciones Unidas, entre otras; (III) Que no se realizaran transacciones con terceros
              provenientes de actividades ilícitas que involucren a los destinatarios dela presente
              declaración. Autorizo a LUEGOPAGO.COM S.A.S a realizar consultas a través de cualquier
              medio, por sí mismo a través de un proveedor, para efectuar las verificaciones
              necesarias con el fin de corroborar la información aquí consignada tanto a la compañía
              que represento, como a todos los miembros y administradores.
            </Typography>
            <Grid container justifyContent="center" className={classes.gridButtonsInfoBasic}>
              <Button
                className={classes.btnBackgroundDeclaration}
                variant="contained"
                color="secondary"
                type="submit"
                onClick={() => closeModalDeclaration()}
              >
                Aceptar
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <form className={classes.form}>
        <Grid container justifyContent="center" alignItems="flex-start" spacing={2}>
          <Grid item xs={12}>
            <Button onClick={handleBack} className={classes.btnSecondary}>
              {`<  Regresar`}
            </Button>
          </Grid>
          <Grid item md={12} xs={12}>
            <Certificates
              data={data}
              dataCertificate={data.dataCertificate}
              setData={setData}
              errorsCertificates={errorsCertificates}
              setErrorsCertificates={setErrorsCertificates}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <FormGroup className={classes.formDeclaration}>
            <FormControlLabel
              className={classes.formInputLabel}
              control={<Checkbox checked={checkDeclaration} onChange={handleChangeDeclaration} />}
            />
            <button type="button" className={classes.formPop} onClick={handleChange}>
              Aceptación de declaración de fondos
            </button>
          </FormGroup>
        </Grid>
        <Grid container justifyContent="flex-end" alignItems="center" style={{ marginTop: '2%' }}>
          <Grid item>
            {isCommercial && data.status && data.status !== PENDING_STATUS ? (
              <Button
                onClick={() => {
                  history.push(`${routesLink.stores}`)
                }}
                variant="contained"
                className={classes.btnBackgroundPrimary}
              >
                Ir a listado de tiendas
              </Button>
            ) : (
              <Button
                color="secondary"
                onClick={onSubmit}
                fullWidth
                disabled={!checkDeclaration}
                variant="contained"
                className={classes.btnBackgroundPrimary}
              >
                {isAdmin && !isCommercial ? 'Actualizar información' : 'Enviar información'}
              </Button>
            )}
          </Grid>
        </Grid>
      </form>
    </>
  )
}
export default LegalDocuments
