/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/anchor-is-valid */
import qs from 'querystring'

import React, { useContext, useEffect, useState } from 'react'

import { Button, CircularProgress, Grid, Link, TextField, Typography } from '@material-ui/core'
import Hidden from '@material-ui/core/Hidden'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import InputLabel from '@material-ui/core/InputLabel'
import { makeStyles } from '@material-ui/core/styles'
import Alert from '@material-ui/lab/Alert'
import Axios from 'axios'
import jwtDecode from 'jwt-decode'
import { useHistory, useLocation } from 'react-router-dom'

import { routeBase } from '../../constants/environments.constants'

import CodeVerification from './CodeVerification'
import styles from './loginPageStyle'
import RecoveryPasswordModal from './RecoveryPasswordModal'

import imgLogin from 'assets/img/imgLogin.png'
import imgParkles from 'assets/img/imgParkles.png'
import CardAvatar from 'components/Card/CardAvatar'
import CardBody from 'components/Card/CardBody'
import DialogAlert from 'components/DialogAlert'
import {
  UserIcon,
  LockIcon,
  EyeIconShow,
  LuegopagoIconLogin,
  EyeIconHidden,
} from 'components/icons/personalizedIcons'
import { CLAIM_ROLE } from 'constants/claimsConstants'
import {
  ADMIN,
  DROPSHIPPER,
  SELLER,
  SUPER_ADMIN,
  ADMIN_SISTECREDITO,
  VOUCHER,
} from 'constants/rolesConst'
import { routesLink } from 'constants/routesConsts'
import { SHOPIFY_API_EXCHANGE_ACCESS_TOKEN, LOGIN_USER, ECOMMERCE_SHOPIFY } from 'constants/urls'
import { ADMIN_PASSWORD } from 'constants/userBlock'
import useAuthContext from 'contextApi/AuthContext'
import { findRole } from 'utils/functions'
import VerifyCode from 'views/RecoveredPassword/VerifyCode'
import { COMMERCIAL_ADVISOR } from 'constants/rolesConst'

const useStyles = makeStyles(styles)

export default function LoginPage({ userEmail, code }) {
  const location = useLocation()

  const {
    getAccessToken,
    dialogAlert,
    setDialogAlert,
    setAccessToken,
    getRoleName,
    setIsShopify,
    persistShopifyData,
  } = useContext(useAuthContext)
  const history = useHistory()

  const [model, setModel] = useState({
    userName: '',
    password: '',
  })
  const [security, setSecurity] = useState({
    password: '',
    showPassword: false,
  })
  const [loading, setLoading] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [openVerifyCode, setOpenVerifyCode] = useState(false)
  const [isOpenRecoveryPassword, setIsOpenRecoveryPassword] = useState(false)
  const [codeVerification, setCodeVerification] = useState(false)
  const [email, setEmail] = useState('')
  const headersShopify = routeBase[process.env.REACT_APP_ENVIRONMENT].routeShopify
  useEffect(() => {
    const token = getAccessToken()
    const coockieRol = getRoleName()
    if (token) {
      if (coockieRol === DROPSHIPPER || coockieRol === SELLER) {
        history.push(routesLink.orders)
      } else if (coockieRol === ADMIN || coockieRol === SUPER_ADMIN) {
        history.push(routesLink.stores)
      }
    }
    if (code !== undefined) setOpenVerifyCode(true)
  }, [code])

  const handleCloseEmailSended = () => {
    setDialogAlert({ ...dialogAlert, open: false })
  }
  const verifyEcommerce = (accessToken) => {
    Axios.get(`${ECOMMERCE_SHOPIFY}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        SCOrigen: headersShopify.SCOrigen,
        country: headersShopify.country,
        'Ocp-Apim-Subscription-Key': headersShopify.subscriptionId,
      },
    })
      .then(function (response) {
        const shopify = response.data.ecommerce === 'shopify'
        persistShopifyData(shopify)
        setIsShopify(shopify)
      })
      .catch(function (error) {
        persistShopifyData(false)
        setIsShopify(false)
        console.log(error)
      })
  }
  const runShopifyIntegration = async (data, accessToken) =>
    new Promise((resolve, reject) => {
      Axios.post(SHOPIFY_API_EXCHANGE_ACCESS_TOKEN, data, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          SCOrigen: headersShopify.SCOrigen,
          country: headersShopify.country,
          'Ocp-Apim-Subscription-Key': headersShopify.subscriptionId,
        },
      })
        .then(() => {
          resolve()
        })
        .catch((err) => {
          console.error(err.message)
          if (err.response?.status === 403) {
            setAlertMessage('Código integración shopify no válido')
          } else {
            setAlertMessage('Ocurrió un error con la integración de shopify')
            reject(err.message)
          }
        })
    })

  const processEcommerceIntegration = async (query, accessToken) => {
    const params = qs.parse(query.replace('?', ''))
    switch (params.ecommerce) {
      case 'shopify':
        await runShopifyIntegration(
          {
            code: params.code,
            hmac: params.hmac,
            shop: params.shop,
            state: params.state,
            timestamp: params.timestamp,
            host: params.host,
          },
          accessToken
        )
        break
      default:
        break
    }
  }
  const onSubmit = async (event) => {
    event.preventDefault()
    setAlertMessage('')
    if (!model.userName || !model.password) {
      setAlertMessage('Ingrese usuario y contraseña')
      return
    }
    const userLowerCase = model.userName.toLowerCase()
    if (userLowerCase && model.password === ADMIN_PASSWORD) {
      setAlertMessage('No puedes entrar con este usuario')
      return
    }
    setLoading(true)
    try {
      const response = await Axios.post(LOGIN_USER, {
        userName: model.userName,
        password: model.password,
      })
      if (response) {
        setLoading(false)
      }
      if (response.data.token) {
        const decoded = jwtDecode(response.data.token)
        if (location.search.length > 0) {
          await processEcommerceIntegration(location.search, response.data.token)
        }

        let role = ''
        if (Array.isArray(decoded[CLAIM_ROLE])) {
          role = findRole(decoded[CLAIM_ROLE])
        } else {
          role = decoded[CLAIM_ROLE]
        }
        if (
          role.toLowerCase() !== DROPSHIPPER.toLowerCase() &&
          role.toLowerCase() !== SELLER.toLowerCase() &&
          role.toLowerCase() !== ADMIN.toLowerCase() &&
          role.toLowerCase() !== SUPER_ADMIN.toLowerCase() &&
          role.toLowerCase() !== ADMIN_SISTECREDITO.toLowerCase() &&
          role.toLowerCase() !== ADMIN_SISTECREDITO.toLowerCase() &&
          role.toLowerCase() !== COMMERCIAL_ADVISOR.toLowerCase()
        ) {
          setAlertMessage('Usuario o contraseña inválidos.')
          return
        }
        verifyEcommerce(response.data.token)
        setAccessToken(response.data.token)
        history.replace(routesLink.orders)
        // window.location.reload()
        return
      }
      setAlertMessage('Respuesta no esperada.')
    } catch (error) {
      setLoading(false)
      setAlertMessage(error.response?.data?.message || '')
    }
  }
  const redirect = () => {
    history.push('/registerStore')
  }
  const handleRecoveryPassword = () => {
    setIsOpenRecoveryPassword(true)
  }
  const handleClickShowPassword = (e) => {
    e.preventDefault()
    setSecurity({ ...security, showPassword: !security.showPassword })
  }
  const handlerClosed = () => {
    setOpenVerifyCode(false)
  }
  const classes = useStyles()

  return (
    <>
      <DialogAlert
        open={dialogAlert.open}
        title={dialogAlert.title}
        message={dialogAlert.message}
        textButtonConfirm={dialogAlert.textButtonConfirm}
        textButtonCancel={dialogAlert.textButtonCancel}
        successful={dialogAlert.successful}
        handleClose={handleCloseEmailSended}
      />
      <DialogAlert
        open={openVerifyCode}
        title="Restablece tu contraseña"
        personalized
        className={classes.cardAlert}
      >
        <VerifyCode userEmail={userEmail} code={code} handlerClosed={handlerClosed} />
      </DialogAlert>
      <Grid container justify="center" alignItems="center" className={classes.containerLogin}>
        <Hidden mdDown>
          <Grid item xs={7} md={7} className={classes.containerLeft}>
            <p className={classes.loginTitle}>Seller center</p>
            <img className={classes.loginParkles} src={imgParkles} alt="imagen login" />
            <img className={classes.loginImg} src={imgLogin} alt="imagen login" />
          </Grid>
        </Hidden>
        <Grid item xs={5}>
          <div className={classes.container}>
            <form className={classes.form}>
              <Grid container direction="column" justify="center" alignItems="flex-start">
                <CardAvatar className={classes.textCenter}>
                  <LuegopagoIconLogin />
                </CardAvatar>
                <CardBody className={classes.bodycard}>
                  {alertMessage && (
                    <Alert
                      severity="error"
                      onClose={() => {
                        setAlertMessage('')
                      }}
                    >
                      {alertMessage}
                    </Alert>
                  )}
                </CardBody>

                <InputLabel htmlFor="storeName" className={classes.labelInput}>
                  Usuario
                </InputLabel>

                <TextField
                  className={classes.input}
                  id="user"
                  formcontrolprops={{
                    fullWidth: true,
                  }}
                  variant="outlined"
                  InputProps={{
                    onChange: ({ target }) => setModel({ ...model, userName: target.value }),
                    startAdornment: (
                      <InputAdornment position="start" className={classes.inputAdornmentIcon}>
                        <UserIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                <InputLabel htmlFor="storeName" className={classes.labelInput}>
                  Contraseña
                </InputLabel>

                <TextField
                  type={security.showPassword ? 'text' : 'password'}
                  className={classes.input}
                  id="password"
                  onKeyUp={(e) => {
                    e.preventDefault()
                    if (e.key === 'Enter' && model.password !== '') onSubmit(e)
                  }}
                  formcontrolprops={{
                    fullWidth: true,
                  }}
                  variant="outlined"
                  InputProps={{
                    onChange: ({ target }) => setModel({ ...model, password: target.value }),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleClickShowPassword}>
                          {security.showPassword ? <EyeIconHidden /> : <EyeIconShow />}
                        </IconButton>
                      </InputAdornment>
                    ),
                    startAdornment: (
                      <InputAdornment position="start" className={classes.inputAdornmentIcon}>
                        <LockIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                <Link
                  onClick={handleRecoveryPassword}
                  variant="body1"
                  className={classes.recoverPassword}
                >
                  Recuperar contraseña
                </Link>
                <Button
                  className={classes.btnAccept}
                  type="button"
                  onClick={onSubmit}
                  disabled={loading}
                >
                  Ingresar
                  {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                </Button>
                <Link onClick={redirect} variant="body1" className={classes.recoverPassword}>
                  Quiero ser vendedor
                </Link>
              </Grid>
            </form>
          </div>
        </Grid>
      </Grid>
      {codeVerification && (
        <CodeVerification
          open={codeVerification}
          setOpen={setCodeVerification}
          model={model}
          email={email}
        />
      )}
      {isOpenRecoveryPassword && (
        <RecoveryPasswordModal open={isOpenRecoveryPassword} setOpen={setIsOpenRecoveryPassword} />
      )}
    </>
  )
}
