import crypto from 'crypto'

import { routeBase } from 'constants/environments.constants'

const KEY = routeBase[process.env.REACT_APP_ENVIRONMENT].soatApiKey
const IV = routeBase[process.env.REACT_APP_ENVIRONMENT].soatApiIV

export function encryptData(text) {
  const cipher = crypto.createCipheriv('aes-256-cbc', KEY, IV)
  let encrypted = cipher.update(text, 'utf8', 'base64')
  encrypted += cipher.final('base64')
  return encrypted.toString('base64')
}
export const formatCammelCaseObject = (data) =>
  data.replace(/"(\w+)"\s*:/g, (_, p1) => {
    return `"${p1[0].toLowerCase()}${p1.slice(1)}":`
  })
export function decryptData(text) {
  const decipher = crypto.createDecipheriv('aes-256-cbc', KEY, IV)
  let decrypted = decipher.update(text, 'base64', 'utf8')
  decrypted += decipher.final('utf8')
  return formatCammelCaseObject(decrypted.toString())
}
