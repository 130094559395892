/* eslint-disable react/prop-types */
import React, { useEffect, useState, useContext } from 'react'

import {
  Button,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  FormControlLabel,
  Switch,
} from '@material-ui/core'
import MUIDataTable from 'mui-datatables'

import useStyles from '../../products.styles'

import VariationsForm from './VariationsForm'

import BackDrop from 'components/BackDrop'
import DialogAlert from 'components/DialogAlert'
import { DeleteIcon, EditIcon } from 'components/icons/personalizedIcons'
import useAuthContext from 'contextApi/AuthContext'
import { useProduct } from 'contextApi/ProductContext'
import convertToCurrencyCop from 'utils/convertToCurrencyCop'

const Variations = ({ setIsLoadingBackDrop }) => {
  const classes = useStyles()
  const { userLoggedin } = useContext(useAuthContext)
  const { product, setProduct, isApprove } = useProduct()
  const [listVariations, setListVariations] = useState([])
  const [variationSelected, setVariationSelected] = useState({})
  const [isOpenVariationForm, setIsOpenVariationForm] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [dialogAlert, setDialogAlert] = useState({
    open: false,
    title: '',
    message: '',
    actionConfirm: null,
    textButtonConfirm: null,
    textButtonCancel: null,
  })
  useEffect(() => {
    setListVariations(product.variations)
  }, [product.variations])
  const openNewVariation = (tableMeta) => {
    if (product.attributes.length === 0) {
      setDialogAlert({
        open: true,
        title: '¡Advertencia!',
        message: 'Por favor habilite cualquier atributo para continuar.',
        textButtonConfirm: 'Cerrar',
      })
    } else if (tableMeta === null) {
      setVariationSelected(null)
      setIsOpenVariationForm(true)
    } else {
      setIsOpenVariationForm(true)
      const founded = product.variations.find(
        (element) => element.sku.trim().toLowerCase() === tableMeta.rowData[2].trim().toLowerCase()
      )
      founded.rowIndex = product.variations.findIndex((element) => element.id === founded.id)
      const variantion = {
        ...founded,
        sku: founded.sku.replace(`${userLoggedin.name}-`, ''),
      }
      setVariationSelected(variantion)
    }
  }
  const handleChangeVisibility = (event, tableMeta) => {
    const founded = product.variations.find(
      (element) => element.sku.trim().toLowerCase() === tableMeta.rowData[2].trim().toLowerCase()
    )
    founded.catalogVisibility = event.target.checked
    const variantion = {
      ...founded,
      catalogVisibility: event.target.checked,
    }
    setVariationSelected(variantion)
  }
  const deleteVariation = async (tableMeta) => {
    if (product.attributes.length === 0) {
      setDialogAlert({
        open: true,
        title: '¡Advertencia!',
        message: 'Por favor habilite cualquier atributo para continuar.',
        textButtonConfirm: 'Cerrar',
      })
    } else if (tableMeta === null) {
      setVariationSelected(null)
      setIsOpenVariationForm(true)
    } else {
      const temp = [...product.variations]
      const objectVariation = temp.find((element) => element.sku.trim() === tableMeta.rowData[2])
      if (objectVariation?.isMemory) {
        setDialogAlert({
          open: true,
          title: '¿Está seguro que desea eliminar esta variación?',
          textButtonConfirm: 'Si',
          textButtonCancel: 'No',
          actionConfirm: () => {
            const foundedOne = temp.filter((element) => element.sku.trim() !== tableMeta.rowData[2])
            setProduct({ ...product, variations: [...foundedOne] })
            setDialogAlert({ open: false })
          },
        })
      } else {
        setIsLoading(true)
        setIsLoading(false)
        setDialogAlert({
          open: true,
          title: '¿Está seguro que desea eliminar esta variación?',
          textButtonConfirm: 'Si',
          textButtonCancel: 'No',
          actionConfirm: () => {
            const variationsProduct = temp.filter((element) => element.id !== tableMeta.rowData[0])
            setProduct({ ...product, variations: variationsProduct })
            setDialogAlert({ open: false })
          },
        })
        setIsLoadingBackDrop(false)
      }
    }
  }
  const columns = [
    {
      name: 'id',
      options: {
        display: false,
        download: false,
      },
    },
    {
      name: 'attributes',
      label: 'NOMBRE VARIACIÓN',
      options: {
        download: false,
        customBodyRender: (value, tableMeta) => {
          const name = []
          product.attributes.map((attrProd) => {
            tableMeta.rowData[1].map((attrVar) => {
              if (attrVar.name === attrProd.name) {
                name.push(
                  `${attrProd.name}: ${
                    attrVar.value?.length !== 0 || attrVar.value === null
                      ? attrVar.value
                      : 'sin asignar'
                  } | `
                )
              }
            })
          })
          return (
            <div>
              <Typography variant="subtitle2">{name}</Typography>
            </div>
          )
        },
      },
    },
    {
      name: 'sku',
      label: 'SKU',
      options: {
        download: false,
      },
    },
    {
      name: 'regularPrice',
      label: 'PRECIO',
      options: {
        download: false,
        customBodyRender: (value) => {
          return <div>{convertToCurrencyCop(Number(value))}</div>
        },
      },
    },
    {
      name: 'stockQuantity',
      label: 'EXISTENCIAS',
      options: {
        download: false,
        customBodyRender: (value) => {
          return <div>{convertToCurrencyCop(Number(value))}</div>
        },
      },
    },
    {
      name: 'catalogVisibility',
      label: 'VISIBLE',
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              <FormControlLabel
                className={classes.switchVisibility}
                control={
                  <Switch
                    onChange={(event) => handleChangeVisibility(event, tableMeta)}
                    checked={value}
                    name="isVisible"
                    color="primary"
                    size="small"
                  />
                }
              />
            </>
          )
        },
      },
    },
    {
      name: '',
      label: '',
      options: {
        download: false,
        filter: false,
        sort: false,
        customBodyRender: (index, tableMeta) => {
          return (
            <>
              {!isApprove ? (
                <>
                  <Tooltip title="Editar variación">
                    <IconButton
                      color="primary"
                      aria-label="Editar variación"
                      onClick={() => {
                        openNewVariation(tableMeta)
                      }}
                      component="span"
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Eliminar variación">
                    <IconButton
                      color="primary"
                      aria-label="Eliminar variación"
                      component="span"
                      onClick={() => {
                        deleteVariation(tableMeta)
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </>
              ) : (
                <Tooltip title="Ver variación">
                  <IconButton
                    color="primary"
                    aria-label="Ver variación"
                    onClick={() => {
                      openNewVariation(tableMeta)
                    }}
                    component="span"
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              )}
            </>
          )
        },
      },
    },
  ]
  const options = {
    responsive: 'scroll',
    search: false,
    filterType: 'dropdown',
    rowHover: true,
    rowsPerPage: 7,
    filter: false,
    sort: false,
    print: false,
    download: false,
    viewColumns: false,
    selectableRows: 'none',
    textLabels: {
      body: {
        noMatch: ` No hay registros`,
      },
      pagination: {
        next: 'Página siguiente',
        previous: 'Página anterior',
        rowsPerPage: 'Filas por página:',
        displayRows: 'de',
      },
      toolbar: {
        search: 'Buscar',
        downloadCsv: 'Descargar CSV',
      },
      selectedRows: {
        text: 'Fila(s) Seleccionada(s)',
      },
    },
  }

  const closeVariationForm = () => {
    setIsOpenVariationForm(false)
  }

  const handleCloseDialog = () => {
    setDialogAlert({ ...dialogAlert, open: false })
  }

  return (
    <>
      <BackDrop open={isLoading} />
      <DialogAlert
        open={dialogAlert.open}
        title={dialogAlert.title}
        handleClose={handleCloseDialog}
        message={dialogAlert.message}
        textButtonConfirm={dialogAlert.textButtonConfirm}
        actionConfirm={dialogAlert.actionConfirm}
        actionCancel={dialogAlert.actionCancel}
        textButtonCancel={dialogAlert.textButtonCancel}
      />
      {isOpenVariationForm && (
        <VariationsForm
          variationSelected={variationSelected}
          setListVariations={setListVariations}
          setIsLoadingBackDrop={setIsLoadingBackDrop}
          listVariations={listVariations}
          onClose={closeVariationForm}
        />
      )}
      <Grid container justify="space-between" alignItems="center" className={classes.divTop}>
        <Typography variant="h6">Variaciones</Typography>
        <Grid className={classes.divButtonsHeader}>
          {!isApprove && (
            <Button
              className={classes.btnBackgroundPrimary}
              onClick={() => openNewVariation(null)}
              variant="contained"
              color="secondary"
            >
              Agregar variación
            </Button>
          )}
        </Grid>

        <MUIDataTable
          data={
            listVariations
              ? listVariations.filter((item) => {
                  if (item.type) {
                    if (item.type !== 'delete') {
                      return item
                    }
                    return false
                  }
                  return item
                })
              : []
          }
          columns={columns}
          options={options}
        />
      </Grid>
    </>
  )
}

export default Variations
