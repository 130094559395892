export const translateNameFiltertoType = (filterName) => {
  switch (filterName) {
    case 'Entrega fallida':
      return 1

    case 'Listo para enviar':
      return 2

    case 'Enviado':
      return 3

    case 'Completado':
      return 4

    case 'Cancelado':
      return 5

    case 'Devuelto':
      return 6

    case 'Procesando':
      return 7

    case 'Entregado':
      return 8

    case 'Posible Fraude':
      return 9
    default:
      return 0
  }
}
export const translateTypeToNameFilter = (filterName) => {
  switch (filterName) {
    case 1:
      return 'Entrega fallida'

    case 2:
      return 'Listo para enviar'

    case 3:
      return 'Enviado'

    case 4:
      return 'Completado'

    case 5:
      return 'Cancelado'

    case 6:
      return 'Devuelto'

    case 7:
      return 'Procesando'

    case 8:
      return 'Entregado'

    case 9:
      return 'Fraude'
    case 10:
      return 'contentDigital'
    case 11:
      return 'PendingReview'
    case 12:
      return 'Approved'
    case 13:
      return 'Rejected'
    case 14:
      return 'AutoApproved'
    default:
      return ''
  }
}
export const translateNameToFilterId = (filterName) => {
  switch (filterName) {
    case 'All':
      return 1
    case 1:
      return 'all'

    case 'Procesando':
      return 2

    case 2:
      return 'processing'

    case 'Listo para enviar':
      return 3

    case 3:
      return 'readyToSend'

    case 'Enviado':
      return 4

    case 4:
      return 'sent'

    case 'Completado':
      return 5

    case 5:
      return 'completed'

    case 'Cancelado':
      return 6

    case 6:
      return 'cancelled'

    case 'Entrega Fallida':
      return 7

    case 7:
      return 'failed'

    case 'Devuelto':
      return 8

    case 8:
      return 'refounded'
    case 9:
      return 'PossibleFraud'
    case 'Fraude':
      return 9
    case 'Posible Fraude':
      return 9
    case 'Pendiente contenido digital':
    case 'contentDigital':
      return 10
    case 'PendingReview':
      return 11
    case 'Approved':
      return 12
    case 12:
      return 'Approved'
    case 13:
      return 'Rejected'
    case 'Rejected':
      return 13
    case 14:
      return 'AutoApproved'
    case 'AutoApproved':
      return 14
    case 15:
      return 'pendingConfirmation'
    case 'pendingConfirmation':
      return 15
    default:
      return ''
  }
}

export const validationNameFilterAdmin = (filterSelected) => {
  const filters = ['Approved', 'Rejected', 'AutoApproved']

  return filters.some((filter) => filter === filterSelected)
}
