/* eslint-disable no-nested-ternary */
/* eslint-disable no-bitwise */
import React, { useEffect, useState } from 'react'

import DateFnsUtils from '@date-io/date-fns'
import {
  Button,
  Checkbox,
  DialogActions,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Tooltip,
} from '@material-ui/core'
import { HelpOutline } from '@material-ui/icons'
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { es } from 'date-fns/locale'
import { useForm } from 'react-hook-form'

import InputField from 'components/InputField'
import { SUPER_ADMIN, VOUCHER } from 'constants/rolesConst'
import dateFormatUtil from 'utils/date.utils'
import useStyles from 'views/Products/products.styles'

const limitedFields = [1, 3]

const UsageInfoStep = ({
  info,
  handleBack,
  handleSubmitStep,
  useTypes,
  creationType,
  sellerInfo,
}) => {
  const [date, setDate] = useState({
    initialDate: new Date().toISOString(),
    finalDate: null,
  })
  const [use, setUse] = useState(info.useType ? info.useType : 2)
  const [status, setStatus] = useState(info.status ? 1 : 0)
  const [isCumulative, setIsCumulative] = useState(info ? info.isCumulative : false)
  const [isForUsersRegistered, setIsForUsersRegistered] = useState(
    info ? info.isForUsersRegistered : false
  )

  const {
    register,
    handleSubmit,
    errors,
    setValue,
    formState: { isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      usageLimit: info.usageLimit,
    },
  })
  const classes = useStyles()

  const onSubmit = (data) => {
    const usageLimit = limitedFields.includes(use) ? data.usageLimit : 0
    handleSubmitStep({
      ...data,
      initialDate: dateFormatUtil(date.initialDate),
      finalDate: dateFormatUtil(date.finalDate),
      isCumulative,
      isForUsersRegistered,
      usageLimit,
      useType: use,
      status: status === 1,
    })
  }

  const loadData = () => {
    if (info.initialDate) {
      const bondId = useTypes.find((useType) => useType.uniqueId === 5)?.id
      const bondProdId = useTypes.find((useType) => useType.uniqueId === 6)?.id
      if (info.discountType === 4 && [bondId, bondProdId].includes(info.useType)) {
        setValue('useType', 2)
        setUse(2)
      }
      setValue('usageLimit', info.usageLimit)
      setDate({
        initialDate: info.initialDate,
        finalDate: info.finalDate,
      })
    }
    if (sellerInfo.role === VOUCHER) {
      setUse(useTypes.find((item) => item.uniqueId === 5).id)
    }
  }
  useEffect(() => {
    loadData()
  }, [])

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container direction="row" spacing={3}>
          <Grid item xs={limitedFields.includes(use) ? 6 : 12}>
            <InputField label="Tipo de uso" error={errors.use && errors.use.message}>
              <Select
                name="useType"
                value={use}
                variant="outlined"
                fullWidth
                disabled={sellerInfo.role === VOUCHER}
                onChange={(event) => {
                  setUse(event.target.value)
                  if ([5, 6].includes(useTypes.find((type) => type.id === use).uniqueId)) {
                    setIsCumulative(false)
                    setValue('isCumulative', false)
                  }
                }}
              >
                {useTypes.map((type) => {
                  const isTypeUniqueIdValid = [5, 6].includes(type.uniqueId)
                  const isSellerRoleValid = ![SUPER_ADMIN, VOUCHER].includes(sellerInfo.role)
                  if (isTypeUniqueIdValid && (info.discountType === 4 || isSellerRoleValid))
                    return null
                  return (
                    <MenuItem key={type.id} value={type.id}>
                      {type.name}
                    </MenuItem>
                  )
                })}
              </Select>
            </InputField>
          </Grid>
          {limitedFields.includes(use) && (
            <Grid item xs={6}>
              <InputField
                label="Límite de uso"
                error={errors.usageLimit && errors.usageLimit.message}
                name="usageLimit"
                type="number"
                ref={register({
                  validate: {
                    required: (value) => {
                      if (limitedFields.includes(use)) {
                        if (!/^\d+$/.test(value)) {
                          return 'El límite de uso solo puede contener números'
                        }
                        return value > 0 || 'El límite de uso debe ser mayor a 1'
                      }
                      return true
                    },
                  },
                })}
              />
            </Grid>
          )}
        </Grid>
        <Grid container direction="row" spacing={3}>
          <Grid item xs={6}>
            <InputField
              label="Fecha inicial"
              error={errors.initialDate && errors.initialDate.message}
            >
              <DateTimePicker
                autoOk
                fullWidth
                name="initialDate"
                variant="inline"
                inputVariant="outlined"
                minDate={info.initialDate ? undefined : new Date()}
                onChange={(initDate) => {
                  setDate({ ...date, initialDate: initDate })
                }}
                value={date.initialDate}
              />
            </InputField>
          </Grid>
          <Grid item xs={6}>
            <InputField label="Fecha final" error={errors.finalDate && errors.finalDate.message}>
              <DateTimePicker
                autoOk
                fullWidth
                name="finalDate"
                variant="inline"
                inputVariant="outlined"
                minDate={date.initialDate}
                onChange={(finalDate) => {
                  setDate({ ...date, finalDate: finalDate })
                }}
                value={date.finalDate}
                helperText="La fecha final debe ser mayor a la fecha inicial"
              />
            </InputField>
          </Grid>
        </Grid>
        <InputField label="Estado" error={errors.status && errors.status.message}>
          <Select
            name="status"
            value={status}
            variant="outlined"
            fullWidth
            disabled={creationType === 2}
            onChange={(event) => {
              setStatus(event.target.value)
            }}
          >
            <MenuItem value={1}>Activo</MenuItem>
            <MenuItem value={0}>Inactivo</MenuItem>
          </Select>
        </InputField>
        <Grid container direction="row">
          {![5, 6].includes(useTypes.find((type) => type.id === use).uniqueId) && (
            <Grid item xs={6}>
              <FormControlLabel
                label="Acumulable con otros cupones"
                control={
                  <Checkbox
                    color="primary"
                    name="isCumulative"
                    value={isCumulative}
                    defaultChecked={isCumulative}
                    onChange={(e) => setIsCumulative(e.target.checked)}
                  />
                }
              />
              <Tooltip
                title="Al marcar esta opción, el cupón será redimible durante el proceso de compra junto a otros cupones que compartan esta característica."
                interactive
                placement="right"
                arrow
                classes={{
                  tooltipPlacementRight: classes.tooltipPlacementRight,
                }}
              >
                <IconButton aria-label="delete" className={classes.margin} size="small">
                  <HelpOutline fontSize="inherit" />
                </IconButton>
              </Tooltip>
            </Grid>
          )}
          <Grid item xs={6}>
            <FormControlLabel
              label="Exclusivo para usuarios registrados"
              control={
                <Checkbox
                  color="primary"
                  name="isForUsersRegistered"
                  value={isForUsersRegistered}
                  defaultChecked={isForUsersRegistered}
                  onChange={(e) => setIsForUsersRegistered(e.target.checked)}
                />
              }
            />
            <Tooltip
              title="Al marcar esta opción, el cupón será redimible únicamente por usuarios registrados en luegopago.com durante el proceso de compra."
              interactive
              placement="right"
              arrow
              classes={{
                tooltipPlacementRight: classes.tooltipPlacementRight,
              }}
            >
              <IconButton aria-label="delete" className={classes.margin} size="small">
                <HelpOutline fontSize="inherit" />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
        <DialogActions>
          <Button onClick={handleBack} className={classes.btnBackgroundErrorPriceSmall}>
            Atrás
          </Button>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            className={classes.btnNewProductsmall}
            disabled={!isValid || !date.finalDate || date.finalDate < date.initialDate}
          >
            Siguiente
          </Button>
        </DialogActions>
      </form>
    </MuiPickersUtilsProvider>
  )
}

export default UsageInfoStep
