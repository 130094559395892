/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react'

import { Backdrop, Fade, IconButton, Modal } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/CloseRounded'

import useStyles from './soat.styles'

import { SOAT_ISSUED_ID } from 'constants/appConstants'
import convertToCurrencyCop from 'utils/convertToCurrencyCop'

const SoatFinancialOverview = ({ isOpen, onClose, financialData, statusId }) => {
  const classes = useStyles()
  if (!financialData) {
    return null
  }
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={isOpen}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isOpen}>
        <div className={classes.paper}>
          <div className={classes.financialDataModalHeader}>
            <h1 className={classes.financialDataModalTitle}>Resumen financiero de SOAT</h1>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </div>
          <div className={classes.financialDataGrid}>
            <div className={classes.financialDataGridChilds}>
              <h2 className={classes.financialDataSubtitle}>
                {statusId === SOAT_ISSUED_ID ? 'SOAT expedido' : 'SOAT no expedido'}
              </h2>
              <span className={classes.financialDataValue}>
                $ {convertToCurrencyCop(financialData.amount)}
              </span>
            </div>
            <div className={classes.financialDataGridChilds}>
              <h2 className={classes.financialDataSubtitle}>Tarifa de servicio</h2>
              <span className={classes.financialDataValue}>
                $ {convertToCurrencyCop(financialData.feeValue)}
              </span>
            </div>
            <div className={classes.financialDataGridFull}>
              <h2 className={classes.financialDataSubtitle}>Valor total</h2>
              <span className={classes.financialDataValue}>
                $ {convertToCurrencyCop(financialData.amount + financialData.feeValue)}
              </span>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  )
}

export default SoatFinancialOverview
