import React from 'react'

import DateFnsUtils from '@date-io/date-fns'
import { Backdrop, Button, Fade, Grid, Modal } from '@material-ui/core'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { es } from 'date-fns/locale'

import useStyles from './soat.styles'

import InputField from 'components/InputField'

const FiltersModal = ({ initialDateRange, isOpen, onClose, onConfirm }) => {
  const classes = useStyles()
  const [fromDate, setFromDate] = React.useState(initialDateRange.from)
  const [toDate, setToDate] = React.useState(initialDateRange.to)

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={isOpen}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isOpen}>
        <div className={classes.paper}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
            <h2 id="transition-modal-title" className={classes.filterTitle}>
              Filtrar ordenes por fecha
            </h2>
            <p className={classes.filterText}>
              Seleccione el rango de fechas para filtrar las ordenes. Tenga en cuenta que solo podrá
              seleccionar un rango de 31 días como máximo.
            </p>
            <br />
            <Grid container direction="column" spacing={1}>
              <Grid item>
                <InputField label="Fecha inicial">
                  <DatePicker
                    autoOk
                    fullWidth
                    name="initialDate"
                    variant="inline"
                    inputVariant="outlined"
                    format="PPPP"
                    minDate={fromDate ? undefined : new Date()}
                    onChange={(initDate) => {
                      const maxDate = new Date(
                        new Date(initDate.getFullYear(), initDate.getMonth() + 1, 0)
                      )
                      setFromDate(initDate)
                      if (toDate > maxDate || toDate < initDate) {
                        setToDate(maxDate)
                      }
                    }}
                    value={fromDate}
                  />
                </InputField>
              </Grid>
              <Grid item>
                <InputField label="Fecha final">
                  <DatePicker
                    autoOk
                    fullWidth
                    name="finalDate"
                    variant="inline"
                    inputVariant="outlined"
                    format="PPPP"
                    minDate={fromDate}
                    onChange={(finalDate) => {
                      setToDate(finalDate)
                    }}
                    value={toDate}
                    maxDate={
                      new Date(fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate() + 31)
                    }
                    helperText="La fecha final debe ser mayor a la fecha inicial"
                  />
                </InputField>
              </Grid>
            </Grid>
            <div className={classes.modalButtonContainer}>
              <Button
                onClick={onClose}
                color="secondary"
                variant="outlined"
                className={classes.btnBackgroundErrorPriceSmall}
              >
                Cancelar
              </Button>
              <Button
                onClick={() =>
                  onConfirm({
                    from: fromDate,
                    to: toDate,
                  })
                }
                className={classes.btnNewProductsmall}
                color="primary"
                variant="contained"
              >
                Confirmar
              </Button>
            </div>
          </MuiPickersUtilsProvider>
        </div>
      </Fade>
    </Modal>
  )
}

export default FiltersModal
