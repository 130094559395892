/* eslint-disable react/prop-types */
import React, { useContext, useState } from 'react'

import {
  TextField,
  FormGroup,
  Grid,
  InputLabel,
  TextareaAutosize,
  Typography,
  InputAdornment,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Axios from 'axios'
import { useRouteMatch } from 'react-router-dom'

import useStyles from '../../products.styles'

import useCustomDialogAlert from 'components/useCustomDialogAlert'
import { DESCRIPTION_LENGHT } from 'constants/productsConstants'
import { MICROSERVICES_PRODUCTS } from 'constants/urls'
import useAuthContext from 'contextApi/AuthContext'
import { useProduct } from 'contextApi/ProductContext'
import { space } from 'utils/utils'

const InfoBasic = ({
  register,
  errors,
  Controller,
  control,
  clearError,
  setError,
  setSkuRepeated,
  valueBrands,
  setValueBrands,
  defaultPropsBrands,
}) => {
  const isEdit = useRouteMatch('/admin/edit-product/:id')
  const isCopy = useRouteMatch('/admin/copy-product/:id')
  const isEditAdmin = useRouteMatch('/admin/admin-edit-product/:id/:idSeller')?.isExact
  const classes = useStyles()
  const { userLoggedin } = useContext(useAuthContext)
  const showDialogAlert = useCustomDialogAlert()
  const { product, setProduct, isApprove } = useProduct()
  const [isDescriptionError, setIsDescriptionError] = useState(false)

  const onChangeDescription = (e) => {
    if (e.target.value.length > DESCRIPTION_LENGHT) {
      setIsDescriptionError(true)
    } else {
      setIsDescriptionError(false)
    }
    setProduct({
      ...product,
      description: e.target.value,
    })
  }

  const validateSku = async (sku) => {
    await Axios({
      url: `${MICROSERVICES_PRODUCTS}/SkuList`,
      method: 'post',
      data: [`${userLoggedin.name}-${sku}`],
    })
      .then(function (response) {
        if (isCopy && response?.data?.length > 0) {
          setSkuRepeated(true)
          showDialogAlert(
            true,
            '¡Advertencia!',
            `El sku ${sku} ya se encuentra registrado.`,
            'Cerrar'
          )
          setError('sku', 'notMatch', 'Sku repetido')
          return
        }
        if (response?.data?.length === 0 || response?.data[0]?.productId === product.id) {
          setSkuRepeated(false)
          clearError('sku')
        } else {
          setSkuRepeated(true)

          showDialogAlert(
            true,
            '¡Advertencia!',
            `El sku ${sku} ya se encuentra registrado.`,
            'Cerrar'
          )
          setError('sku', 'notMatch', 'Sku repetido')
          // isSkuRepeat = false
        }
      })
      .catch(() => {})
  }
  const handleChangeAutocomplete = (event, newValue) => {
    const newBrand = {
      id: newValue.id,
      name: newValue.name,
      slug: '',
      imageUrl: newValue.imageSrc,
      isActive: newValue.isActive,
      description: '',
    }
    setProduct({
      ...product,
      brands: [newBrand],
    })
  }
  return (
    <Grid
      container
      justify="space-between"
      alignItems="flex-start"
      className={classes.headerDetail}
    >
      <Typography variant="subtitle1" className={classes.ProductPartTypo}>
        Información básica
      </Typography>
      <FormGroup className={classes.formInput}>
        <InputLabel className={classes.labelSize}>Nombre del producto</InputLabel>
        <TextField
          fullWidth
          id="name"
          name="name"
          variant="outlined"
          onChange={(event) =>
            setProduct({
              ...product,
              name: event.target.value,
            })
          }
          disabled
          value={product.name}
          inputRef={register}
        />
      </FormGroup>
      <FormGroup className={classes.formInput}>
        <InputLabel error={isEdit || isEditAdmin ? '' : !!errors.sku} className={classes.labelSize}>
          SKU
        </InputLabel>
        <TextField
          fullWidth
          id="sku"
          name="sku"
          variant="outlined"
          onKeyPress={space}
          onChange={(event) => {
            if (event.target.value.length <= 15) {
              setProduct({
                ...product,
                sku: event.target.value.trim().toUpperCase(),
              })
            }
          }}
          onBlur={(event) => {
            if (event.target.value !== '') {
              validateSku(event.target.value.trim().toUpperCase())
            }
          }}
          InputProps={{
            startAdornment: !isEditAdmin && (
              <InputAdornment position="start" variant="outlined">
                {`${userLoggedin.name.substring(0, 5)}-`}
              </InputAdornment>
            ),
          }}
          value={product.sku.replace(`${userLoggedin.name}-`, '').trim().toUpperCase()}
          inputRef={register}
          error={!!errors.sku}
          helperText={isEdit || isEditAdmin ? '' : errors?.sku?.message}
          disabled={isEdit || isApprove || isEditAdmin}
        />
      </FormGroup>
      <FormGroup className={classes.descriptionProduct}>
        <InputLabel className={classes.labelSize}>Descripción del producto (opcional) </InputLabel>
        <Controller
          control={control}
          name="description"
          rules={{ maxLength: DESCRIPTION_LENGHT, max: DESCRIPTION_LENGHT }}
          render={(props) => (
            <TextareaAutosize
              id="description"
              rowsMax={4}
              disabled={isApprove}
              className={classes.descriptionInfoBasic}
              onChange={(e) => props.onChange(onChangeDescription(e))}
              value={product?.description || ''}
            />
          )}
        />
        {errors.description && (
          <span className={classes.spanError}>{`Máximo ${DESCRIPTION_LENGHT} caracteres`}</span>
        )}
        {isDescriptionError && (
          <span className={classes.spanError}>{`Máximo ${DESCRIPTION_LENGHT} caracteres`}</span>
        )}
      </FormGroup>
      <FormGroup className={classes.selectBrandsProducts}>
        <FormGroup>
          <InputLabel className={classes.labelSize}>Marca (opcional)</InputLabel>
          <Autocomplete
            {...defaultPropsBrands}
            className={classes.autocompletePadding}
            value={valueBrands}
            onChange={(event, newValue) => {
              setValueBrands(newValue)
              handleChangeAutocomplete(event, newValue)
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                inputRef={register}
                label=""
                margin="normal"
                variant="outlined"
                className={classes.autocompleteDetailProduct}
              />
            )}
          />
        </FormGroup>
      </FormGroup>
    </Grid>
  )
}

export default InfoBasic
