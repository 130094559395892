import React from 'react'

import { useParams } from 'react-router-dom'

import Footer from './components/Footer'
import Header from './components/Header'
import styles from './generalConfig.styles'
import StepperDetail from './StepperDetail'

const DetailSellerStore = () => {
  const { id, email } = useParams()
  const classes = styles()

  return (
    <div className={classes.main}>
      <div>
        {email && <Header />}

        <StepperDetail id={id} email={email} lockEmail />
      </div>
      {email && <Footer email={email} />}
    </div>
  )
}
export default DetailSellerStore
