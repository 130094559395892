/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react'

import { Backdrop, Button, Fade, Modal } from '@material-ui/core'

import useStyles from './soat.styles'

import { ExclamationIcon } from 'components/icons/personalizedIcons'

const MaintenanceConfirmation = ({ isActive, onConfirm, onClose, isOpen }) => {
  const classes = useStyles()

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={isOpen}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isOpen}>
        <div className={classes.paper}>
          <div className={classes.modalIcon}>
            <ExclamationIcon />
          </div>
          <h2 id="transition-modal-title" className={classes.modalCenteredTitle}>
            ¡Está a punto de {isActive ? 'desactivar' : 'activar'} el modo mantenimiento de SOAT
            Luegopago!
          </h2>
          <p className={classes.modalMaintenanceText}>
            {isActive
              ? 'Al desactivar el modo mantenimiento, los usuarios podrán realizar cotizaciones y compras de pólizas de SOAT en Luegopago.'
              : 'Al activar el modo mantenimiento, los usuarios no podrán realizar cotizaciones ni compras de pólizas de SOAT en Luegopago. Tenga en cuenta que este modo permanecerá activo hasta que lo desactive manualmente.'}
          </p>
          <div className={classes.modalButtonContainer}>
            <Button
              onClick={onClose}
              color="secondary"
              variant="outlined"
              className={classes.btnBackgroundErrorPriceSmall}
            >
              Cancelar
            </Button>
            <Button
              onClick={onConfirm}
              className={classes.btnNewProductsmall}
              color="primary"
              variant="contained"
            >
              Confirmar
            </Button>
          </div>
        </div>
      </Fade>
    </Modal>
  )
}

export default MaintenanceConfirmation
