import { DEFAULT_IP } from 'constants/appConstants'
import { CLUDFLARE_IP_URL } from 'constants/urls'

const getClienIp = async () => {
  try {
    const response = await fetch(CLUDFLARE_IP_URL)
    const data = await response.text()
    const object = data.split('\n').reduce((acc, line) => {
      const [key, value] = line.split('=')
      acc[key] = value
      return acc
    }, {})
    return object.ip
  } catch (error) {
    console.error(error)
    return DEFAULT_IP
  }
}

export default getClienIp
