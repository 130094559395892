import React from 'react'

import StepperDetail from 'views/StoreDetail/StepperDetail'

const StoreEnlistment = () => {
  return (
    <div>
      {' '}
      <StepperDetail />
    </div>
  )
}

export default StoreEnlistment
