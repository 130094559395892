/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
import React, { useContext, useEffect, useState } from 'react'

import {
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  Typography,
  withStyles,
  FormGroup,
  TextareaAutosize,
} from '@material-ui/core'
import Axios from 'axios'
import moment from 'moment'
import { useHistory, useRouteMatch } from 'react-router-dom'

import CategoriesListBreadCrumb from './categoriesListBreadCrumb'
import useStyles from './products.styles'
import AtributtesVariationsProduct from './Tabs/AtributtesVariationsProduct'
import ImagesProduct from './Tabs/ImagesProduct'
import InfoProduct from './Tabs/InfoProduct'
import PriceProduct from './Tabs/PriceProducts'

import BackDrop from 'components/BackDrop'
import Button from 'components/CustomButtons/Button'
import DialogAlert from 'components/DialogAlert'
import useCustomDialogAlert from 'components/useCustomDialogAlert'
import { PROMOTIONS } from 'constants/promotions'
import { routesLink } from 'constants/routesConsts'
import { API_PRODUCTS, MICROSERVICES_PRODUCTS } from 'constants/urls'
import useAuthContext from 'contextApi/AuthContext'
import { useProduct } from 'contextApi/ProductContext'
import ProductCategorization from 'views/ProductCategorization'

const CircleConnector = withStyles({
  alternativeLabel: {
    top: 20,
    left: 'calc(-50% + 30px)',
    right: 'calc(50% + 30px)',
  },
  active: {
    '& $line': {
      borderColor: '#6064FF',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#6064FF',
    },
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector)

export default function ProductStepper() {
  const classes = useStyles()
  const history = useHistory()
  const showDialogAlert = useCustomDialogAlert()
  const { userLoggedin } = useContext(useAuthContext)
  const {
    product,
    setProduct,
    activeStep,
    setActiveStep,
    resetData,
    warehouses,
    listProductsShopify,
    isApprove,
    setIsApprove,
    setSellerIdSelected,
    isModified,
    cities,
    country,
    setIsAdmin,
  } = useProduct()
  const [dialogAlert, setDialogAlert] = useState({
    open: false,
    title: '',
    message: '',
    action: null,
  })
  const isEdit = useRouteMatch('/admin/edit-product/:id')
  const isEditAdmin = useRouteMatch('/admin/admin-edit-product/:id/:idSeller')
  const isAdd = useRouteMatch('/admin/add-product')
  const isCopy = useRouteMatch('/admin/copy-product/:id')
  const isApproveAdmin = useRouteMatch('/admin/approve-product/:id/:idSeller')
  const isShopify = useRouteMatch('/admin/shopify-edit/:shopifyId')
  const [update, setUpdate] = useState(false)
  const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(false)
  const getProduct = async (id, idSeller) => {
    setIsLoadingBackDrop(true)

    const url =
      isApproveAdmin || isEditAdmin
        ? `${MICROSERVICES_PRODUCTS}/${id}?sellerIdRequest=${idSeller}`
        : `${MICROSERVICES_PRODUCTS}/${id}`
    await Axios.get(url)
      .then((result) => {
        if (result?.data?.data) {
          setProduct({
            ...product,
            id: result.data.data.id,
            name: result.data.data.name,
            description: result.data.data.description.replace(/<[^>]*>?/g, ''),
            isUsed: result.data.data.isUsed,
            checkTransport: result.data.data.checkTransport,
            assumedValue: result.data.data.assumedValue ? result.data.data.assumedValue : 0,
            attributes: result.data.data.attributes,
            dimensions: result.data.data.dimensions,
            isReconditioned: result.data.data.isReconditioned,
            categories: result.data.data.categories.sort((prev, next) => prev.id - next.id),
            sku: isCopy ? '' : result.data.data.sku,
            regularPrice: result.data.data.regularPrice,
            status: isCopy ? 'draft' : result.data.data.status,
            stockQuantity: result.data.data.stockQuantity.toString() || 0,
            type: result.data.data.type,
            variations: result.data.data.variations || [],
            images: result.data.data.images,
            warehouses: result.data.data.warehouses,
            seller: result.data.data.seller,
            catalogVisibility: result.data.data.catalogVisibility,
            quantityPerShoppingCart: result.data.data.quantityPerShoppingCart,
            promotions: result.data.data.promotions || [],
            brands: result.data.data.brands || [],
            warranty: result.data.data.warranty || '',
          })
          setIsLoadingBackDrop(false)
        }
      })
      .catch(() => {
        setIsLoadingBackDrop(false)
      })
  }
  const getProductShopify = async (id) => {
    setIsLoadingBackDrop(true)
    const productFromShopify = await listProductsShopify.find(
      (productItem) => productItem.id.toString() === id.toString()
    )
    setProduct({
      id: '',
      name: productFromShopify?.title || '',
      description: '',
      isUsed: '',
      checkTransport: '',
      assumedValue: '',
      attributes: '',
      dimensions: '',
      categories: '',
      sku: '',
      weight: productFromShopify?.variants[0].weight || '',
      regularPrice: '',
      sale_price: '',
      status: '',
      stockQuantity: '',
      type: productFromShopify?.variants?.length > 0 ? 'variable' : 'simple',
      variations: '',
      images: productFromShopify?.images || [],
      date_on_sale_from: '',
      date_on_sale_to: '',
    })
    setIsLoadingBackDrop(false)
  }

  const getSteps = () => {
    if (isShopify && product.type === 'variable') {
      return ['Información del producto', 'Gestionar imágenes', 'Atributos y variaciones']
    }
    if (isShopify && product.type === 'simple') {
      return ['Información del producto', 'Precio', 'Gestionar imágenes']
    }
    if (product.type === 'variable') {
      return [
        'Categoría',
        'Información del producto',
        'Gestionar imágenes',
        'Atributos y variaciones',
      ]
    }
    return ['Categoría', 'Información del producto', 'Precio', 'Gestionar imágenes']
  }

  useEffect(() => {
    setActiveStep(0)
    if (isAdd) {
      resetData()
    }
    if (isEdit) {
      setUpdate(true)
      getProduct(isEdit.params.id)
    }
    if (isShopify) {
      getProductShopify(isShopify.params.shopifyId)
    }
    if (isCopy) {
      getProduct(isCopy.params.id)
    }
    if (isEditAdmin) {
      setUpdate(true)
      setIsApprove(false)
      setIsAdmin(true)
      setSellerIdSelected(isEditAdmin.params.idSeller)
      getProduct(isEditAdmin.params.id, isEditAdmin.params.idSeller)
    }
    if (isApproveAdmin) {
      setIsApprove(true)
      setSellerIdSelected(isApproveAdmin.params.idSeller)
      getProduct(isApproveAdmin.params.id, isApproveAdmin.params.idSeller)
    }
    return () => {
      resetData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const variationData = () => {
    console.log('Actualización de variaciones en proceso...')
    setIsLoadingBackDrop(true)
    product.regularPrice = '1'
    product.variations.map((vari) => {
      vari.type = 'variation'
      vari.stockQuantity = vari.stockQuantity.toString().replace(/\./g, '')
    })
    let url = ''
    if (update && isEditAdmin) {
      url = `${MICROSERVICES_PRODUCTS}/${product.id}?sellerIdRequest=${isEditAdmin.params.idSeller}`
    } else if (update) {
      url = `${MICROSERVICES_PRODUCTS}/${product.id}`
    }
    const respuesta = Axios({
      url: update ? url : MICROSERVICES_PRODUCTS,
      method: update ? 'PUT' : 'POST',
      data: product,
    })
      .then((response) => {
        setIsLoadingBackDrop(false)
        if (response) {
          setIsLoadingBackDrop(false)
        }
        if (response.status === 200 || response.status === 201) {
          setIsLoadingBackDrop(false)
          if (isEdit) {
            showDialogAlert(
              true,
              '¡Importante!',
              !respuesta?.data?.header?.message
                ? 'Se modificó correctamente el producto'
                : respuesta?.data?.header?.message,
              'Cerrar',
              '',
              true
            )
            if (update && isEditAdmin) {
              history.push(
                `${routesLink.adminEditProduct}/${product.id}/${isEditAdmin.params.idSeller}`
              )
            } else {
              history.push(`${routesLink.products}`)
            }
          } else {
            setDialogAlert({
              open: true,
              title: '',
              message:
                update && isEditAdmin
                  ? 'Se modificó correctamente el producto'
                  : `El producto está sujeto a revisión para su respectiva publicación.`,
              actionConfirm: () => {
                if (update && isEditAdmin) {
                  history.push(`${routesLink.productsBySeller}/${isEditAdmin.params.idSeller}`)
                } else {
                  history.push(`${routesLink.products}`)
                }
              },
              textButtonConfirm: 'Aceptar',
            })
          }

          if (isCopy) {
            history.push(`${routesLink.copyProduct}/${respuesta.data.id}`)
          }
          return true
        }
      })
      .catch((e) => {
        console.log(e)
        setIsLoadingBackDrop(false)
      })
  }
  const saveVariations = async () => {
    const missingOption = false
    if (product?.variations?.length > 0) {
      variationData()
      if (missingOption) {
        showDialogAlert(
          true,
          '¡Advertencia!',
          `Todos los atributos deben tener una opción.`,
          'Cerrar'
        )
        return false
      }
    }
  }

  const saveAndPublish = async (event) => {
    event.preventDefault()
    if (product.type === 'variable') {
      product.stockQuantity = 1
      product.regularPrice = 1
      let missingOption = false
      if (product?.variations?.length > 0) {
        product.variations
          .filter((v) => v.type !== 'delete')
          .map((variation) => {
            variation.attributes.map((attribute) => {
              if (attribute.option?.length === 0 || attribute.option === null) missingOption = true
              return false
            })
          })
      } else {
        showDialogAlert(
          true,
          '¡Advertencia!',
          'Debe ingresar una variación del producto.',
          'Cerrar'
        )
        return false
      }
      if (missingOption) {
        showDialogAlert(
          true,
          '¡Advertencia!',
          'Todos los atributos deben tener una opción seleccionada.',
          'Cerrar'
        )
        return false
      }
      let isAnyAttributes = false
      product.variations.map((productVariation) => {
        const value = productVariation.attributes.find(
          (attributes) => attributes.value.length === 0
        )
        if (value) isAnyAttributes = true
      })
      if (isAnyAttributes) {
        showDialogAlert(
          true,
          '¡Advertencia!',
          'Valide que variaciones tengan todos los atributos seleccionados',
          'Cerrar'
        )
        return false
      }
    }
    const objectPromotion = product.promotions.find((promo) => promo.name === PROMOTIONS)
    if (product.images.length <= 0) {
      showDialogAlert(true, '¡Advertencia!', `Debe ingresar una imagen del producto`, 'Cerrar')
      return false
    }
    if (!product.name || !product.sku || !product.categories || product.categories.length === 0) {
      showDialogAlert(
        true,
        '¡Advertencia!',
        `Los campos no pueden estar vacíos en el tab INFORMACIÓN DEL PRODUCTO`,
        'Cerrar'
      )
      return false
    }
    if (
      (!product.type === 'virtual' || !product.type === 'service') &&
      (!product.dimensions ||
        !product.dimensions.height ||
        !product.dimensions.width ||
        !product.dimensions.length ||
        !product.dimensions.weight)
    ) {
      showDialogAlert(
        true,
        '¡Advertencia!',
        `Los campos no pueden estar vacíos en el tab DETALLES DEL PRODUCTO`,
        'Cerrar'
      )
      return false
    }
    if (product.type === 'simple') {
      if (
        product.stockQuantity < 0 ||
        product.stockQuantity === undefined ||
        product.warehouses[0].warehouseId === 0 ||
        product.warehouses[0].warehouseId === undefined
      ) {
        showDialogAlert(
          true,
          '¡Advertencia!',
          `Los campos no pueden estar vacíos en el tab DETALLES DEL PRODUCTO`,
          'Cerrar'
        )
        return false
      }
    } else {
      const warehousesObject = [
        {
          warehouseId: `${warehouses[0].id}`,
          warehouseName: `${warehouses[0].title}`,
          warehouseLocation: {
            country:
              country.length > 0
                ? country.find((countryName) => countryName.id === warehouses[0].countryId)?.name
                : '',
            city:
              cities.length > 0
                ? cities.find((city) => city.id === warehouses[0].cityId)?.name
                : '',
            address: `${warehouses[0]?.warehouseAddress || ''}`,
            latitude: `${warehouses[0]?.latitude || '0'}`,
            longitude: `${warehouses[0]?.longitude || '0'}`,
          },
        },
      ]
      product.warehouses = [...warehousesObject]
    }
    if (
      product.dimensions.height < 0 ||
      product.dimensions.width < 0 ||
      product.dimensions.length < 0 ||
      product.dimensions.weight < 0 ||
      (product.type !== 'variable' && product.regularPrice < 0)
    ) {
      showDialogAlert(
        true,
        '¡Advertencia!',
        `Los campos no pueden estar vacíos en el tab DETALLES DEL PRODUCTO`,
        'Cerrar'
      )
      return false
    }
    if (product.type === 'simple' && !product.regularPrice) {
      showDialogAlert(
        true,
        '¡Advertencia!',
        `Los campos no pueden estar vacíos en el tab PRECIO DEL PRODUCTO`,
        'Cerrar'
      )
      return false
    }
    if (
      objectPromotion?.dateOnSaleTo !== undefined &&
      objectPromotion?.dateOnSaleFrom !== undefined &&
      moment(objectPromotion?.dateOnSaleTo) < moment(objectPromotion?.dateOnSaleFrom)
    ) {
      showDialogAlert(
        true,
        '¡Advertencia!',
        `La fecha final de promoción debe ser mayor a la fecha inicial`,
        'Cerrar'
      )
      return false
    }
    const date = new Date()
    const month = date.getMonth() < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
    const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
    const actualDayInitialDate = `${date.getFullYear()}-${month}-${day}T00:00:00`
    const actualDayFinalDate = `${date.getFullYear()}-${month}-${day}T23:59:59`
    if (product.type === 'simple') {
      if ((!product.date_on_sale_to || !product.date_on_sale_from) && product.sale_price && isAdd) {
        showDialogAlert(true, '¡Advertencia!', `Las fechas de promoción son obligatorias`, 'Cerrar')
        return false
      }

      if (
        objectPromotion?.dateOnSaleFrom !== undefined &&
        moment(objectPromotion?.dateOnSaleFrom) < moment(actualDayInitialDate) &&
        isAdd
      ) {
        showDialogAlert(
          true,
          '¡Advertencia!',
          `La fecha inicial de promoción debe ser mayor o igual al día de hoy`,
          'Cerrar'
        )
        return false
      }
      if (
        objectPromotion?.dateOnSaleTo !== undefined &&
        moment(objectPromotion?.dateOnSaleTo) < moment(actualDayFinalDate) &&
        objectPromotion?.value &&
        isAdd
      ) {
        showDialogAlert(
          true,
          '¡Advertencia!',
          `La fecha final de promoción debe ser mayor o igual al día de hoy`,
          'Cerrar'
        )
        return false
      }
    }
    if (product.checkTransport && product.assumedValue === '') {
      showDialogAlert(
        true,
        '¡Advertencia!',
        `Los campos no pueden estar vacíos en el tab DETALLES DEL PRODUCTO`,
        'Cerrar'
      )
      return false
    }
    try {
      setIsLoadingBackDrop(true)
      product.sku = product.sku.replace(`${userLoggedin.name}-`, '')
      if (product.checkTransport === false) {
        product.assumedValue = 0
        if (product.packageType) delete product.packageType
      }
      if (product.assumedValue === '' || product.assumedValue === null) {
        product.assumedValue = 0
      }
      // delete product.categories[product.categories.length -1].atributtes
      if (!product.warranty) {
        delete product.warranty
      }
      delete product.is_iva_discount
      delete product.iva_discount_percentage
      delete product.date_on_sale_from
      delete product.date_on_sale_to
      delete product.sale_price
      delete product.rowIndex
      product.assumedValue = parseInt(product.assumedValue, 10)
      product.images.forEach((element) => {
        delete element.showOptions
      })
      if (product.type === 'variable' && product.variations) {
        product.variations.forEach((variation) => {
          variation.attributes.forEach((element) => {
            delete element.id
          })
        })
        saveVariations()
      } else {
        setIsLoadingBackDrop(true)
        let url = ''
        if (update && isEditAdmin) {
          url = `${MICROSERVICES_PRODUCTS}/${product.id}?sellerIdRequest=${isEditAdmin.params.idSeller}`
        } else if (update) {
          url = `${MICROSERVICES_PRODUCTS}/${product.id}`
        }
        const respuesta = await Axios({
          url: update ? url : MICROSERVICES_PRODUCTS,
          method: update ? 'PUT' : 'POST',
          data: product,
        })
        if (respuesta) {
          setIsLoadingBackDrop(false)
        }
        if (respuesta.status === 200 || respuesta.status === 201) {
          setIsLoadingBackDrop(false)
          if (isEdit) {
            showDialogAlert(
              true,
              '¡Importante!',
              respuesta.data.code == '24'
                ? 'Algunos cambios quedan pendiente por aprobación'
                : 'Se modificó correctamente el producto',
              'Cerrar',
              '',
              true
            )
            history.push(`${routesLink.products}`)
          } else {
            setDialogAlert({
              open: true,
              title: '',
              message:
                update && isEditAdmin
                  ? 'Se modificó correctamente el producto'
                  : `El producto está sujeto a revisión para su respectiva publicación.`,
              actionConfirm: () => {
                if (update && isEditAdmin) {
                  history.push(`${routesLink.productsBySeller}/${isEditAdmin.params.idSeller}`)
                } else {
                  history.push(`${routesLink.products}`)
                }
              },
              textButtonConfirm: 'Aceptar',
            })
          }

          if (isCopy) {
            history.push(`${routesLink.copyProduct}/${respuesta.data.id}`)
          }
          return true
        }
      }
    } catch (error) {
      setIsLoadingBackDrop(false)
      console.log('error ', error)
    }
    return false
  }

  const approveMultipleProducts = async (productList, type, obs = '') => {
    setIsLoadingBackDrop(true)
    let url = `${API_PRODUCTS}/ModifiedProductsActions?actionsModified=${type}`
    if (type === 0) {
      if (!isModified) {
        url = `${API_PRODUCTS}/ActionToProductList`
      } else if (obs !== '') {
        url = `${API_PRODUCTS}/ModifiedProductsActions?${obs}&actionsModified=${type}`
      }
    }
    await Axios.post(
      url,
      !isModified
        ? {
            ProductIdList: productList,
            type: type + 1,
          }
        : productList
    )
      .then((response) => {
        setIsLoadingBackDrop(false)
        if (response.status === 200) {
          showDialogAlert(
            true,
            type === 0
              ? '¡Se aprobo correctamente el producto!'
              : '¡Se rechazo correctamente el producto!',
            null,
            'Cerrar',
            '',
            true
          )
        }
      })
      .catch(() => {
        setIsLoadingBackDrop(false)
      })
  }

  const selectApprovalMethod = async (typeSelected) => {
    if (typeSelected === '') {
      return false
    }

    if (typeSelected === 0) {
      showDialogAlert(
        true,
        `Aprobación de productos`,
        `¿Está seguro que desea aprobar este producto?`,
        'Aceptar',
        'Cancelar',
        false,
        () => approveMultipleProducts([product?.id], typeSelected)
      )
    } else {
      showDialogAlert(
        true,
        `Rechazar productos`,
        null,
        'Aceptar',
        'Cancelar',
        false,
        () => {
          if (document.querySelector('#description').value !== '') {
            approveMultipleProducts(
              [product?.id],
              typeSelected,
              document.querySelector('#description').value
            )
          } else {
            showDialogAlert(
              true,
              `La observacion es obligatoria`,
              'por favor ingrese la observacion del rechazo',
              'Aceptar'
            )
            return false
          }
          return true
        },
        null,
        true,

        <>
          <Typography variant="h6">¿Esta seguro que desea rechazar este producto?</Typography>
          <Typography variant="h6">Escribe el por que:</Typography>
          <FormGroup className={classes.descriptionProduct}>
            <TextareaAutosize
              id="description"
              inputProps={{ maxLength: 250 }}
              maxRows={3}
              className={classes.descriptionInfoBasic}
            />
          </FormGroup>
        </>,
        null
      )
    }
    return true
  }

  const handleCloseDialogAlert = () => {
    setDialogAlert({ ...dialogAlert, open: false })
  }
  function getStepContent(stepIndex) {
    if (isShopify) {
      switch (stepIndex) {
        case 0:
          return <InfoProduct isShopifyValidation="shopify" />
        case 1:
          return <ImagesProduct isProductStepper saveAndPublish={saveAndPublish} />
        case 2:
          return (
            <AtributtesVariationsProduct
              setIsLoadingBackDrop={setIsLoadingBackDrop}
              saveAndPublish={saveAndPublish}
            />
          )

        default:
          return 'stepIndex desconocido'
      }
    } else if (product.type === 'variable') {
      switch (stepIndex) {
        case 0:
          return <ProductCategorization />
        case 1:
          return <InfoProduct />
        case 2:
          return <ImagesProduct isProductStepper saveAndPublish={saveAndPublish} />
        case 3:
          return (
            <AtributtesVariationsProduct
              setIsLoadingBackDrop={setIsLoadingBackDrop}
              saveAndPublish={saveAndPublish}
              selectApprovalMethod={selectApprovalMethod}
            />
          )

        default:
          return 'stepIndex desconocido'
      }
    } else {
      switch (stepIndex) {
        case 0:
          return <ProductCategorization />

        case 1:
          return <InfoProduct />
        case 2:
          return <PriceProduct isAdd={isAdd} isCopy={isCopy} isEdit={isEdit} />
        case 3:
          return (
            <ImagesProduct
              isProductStepper
              saveAndPublish={saveAndPublish}
              selectApprovalMethod={selectApprovalMethod}
            />
          )
        default:
          return 'stepIndex desconocido'
      }
    }
  }

  const steps = getSteps()

  const handleReset = () => {
    setActiveStep(0)
  }

  return (
    <div className={classes.root}>
      <DialogAlert
        open={dialogAlert.open}
        title={dialogAlert.title}
        handleClose={handleCloseDialogAlert}
        message={dialogAlert.message}
        textButtonConfirm={dialogAlert.textButtonConfirm}
        actionConfirm={dialogAlert.actionConfirm}
        textButtonCancel={dialogAlert.textButtonCancel}
      />
      <Typography variant="h6">
        {isEdit ? 'Editar ' : isApprove ? 'Aprobar ' : 'Agregar '}
        nuevo producto
      </Typography>
      <BackDrop open={isLoadingBackDrop} />
      <Stepper activeStep={activeStep} alternativeLabel connector={<CircleConnector />}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel
              classes={{
                label: classes.labelStepLabel,
                active: classes.activeStepLabel,
                completed: classes.completedStepLabel,
              }}
              StepIconProps={{
                classes: {
                  root: classes.rootStepIcon,
                  text: classes.text,
                  active: classes.active,
                  completed: classes.completed,
                },
              }}
            >
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div>
            <Typography className={classes.instructions}>Todos los pasos completados</Typography>
            <Button onClick={handleReset}>Reiniciar</Button>
          </div>
        ) : (
          <div>{getStepContent(activeStep)}</div>
        )}
      </div>
    </div>
  )
}
