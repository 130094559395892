import React, { useContext } from 'react'

import { Grid, Button } from '@material-ui/core'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

import Form from '../components/Form'
import TypePerson from '../components/TypePerson'
import styles from '../generalConfig.styles'

import { COMMERCIAL_ADVISOR } from 'constants/rolesConst'
import { routesLink } from 'constants/routesConsts'
import { PENDING_STATUS } from 'constants/storeConstants'
import useAuthContext from 'contextApi/AuthContext'

const PersonalData = ({ data, setData, handleNext, cites, setCites, isAdmin, lockEmail }) => {
  const classes = styles()
  const { register, handleSubmit, errors } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  })
  const history = useHistory()
  const { userLoggedin } = useContext(useAuthContext)
  const isCommercialAdvisor = userLoggedin?.role === COMMERCIAL_ADVISOR
  const onSubmit = () => {
    handleNext()
  }
  const backToPersonType = () => {
    setData({
      ...data,
      personalData: {
        ...data.personalData,
        typeOfPersonId: null,
      },
    })
  }
  return (
    <div className={classes.form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container justifyContent="center" alignItems="flex-start" spacing={2}>
          <Grid item md={12} xs={12}>
            {!data?.personalData?.typeOfPersonId ? (
              <TypePerson data={data} setData={setData} errors={errors} register={register} />
            ) : (
              <>
                {isCommercialAdvisor && (data.status === PENDING_STATUS || !data.status) && (
                  <Button onClick={backToPersonType} className={classes.btnSecondary}>
                    {`<  Regresar`}
                  </Button>
                )}
                <Form
                  data={data}
                  setData={setData}
                  errors={errors}
                  register={register}
                  cites={cites}
                  setCites={setCites}
                  isCommercialAdvisor={isCommercialAdvisor}
                  lockEmail={lockEmail}
                />
              </>
            )}
          </Grid>
        </Grid>
        {data?.personalData?.typeOfPersonId && (
          <Grid
            container
            justifyContent={
              isCommercialAdvisor && data.status && data.status !== PENDING_STATUS
                ? 'space-between'
                : 'flex-end'
            }
            alignItems="center"
            style={{ marginTop: '2%' }}
          >
            {isCommercialAdvisor && data.status && data.status !== PENDING_STATUS && (
              <Grid item xs={6}>
                <Button
                  onClick={() => {
                    history.push(`${routesLink.stores}`)
                  }}
                  variant="text"
                  className={classes.btnSecondary}
                >
                  Ir a listado de tiendas
                </Button>
              </Grid>
            )}
            {!isAdmin && (
              <Grid item xs={6}>
                <Button onClick={backToPersonType} className={classes.btnSecondary}>
                  {`<  Regresar`}
                </Button>
              </Grid>
            )}
            <Grid item xs={6}>
              <Button
                color="secondary"
                type="submit"
                fullWidth
                variant="contained"
                className={classes.btnBackgroundPrimary}
              >
                Continuar
              </Button>
            </Grid>
          </Grid>
        )}
      </form>
    </div>
  )
}
export default PersonalData
