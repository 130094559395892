/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'

import {
  Button,
  DialogActions,
  Grid,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
} from '@material-ui/core'
import { useForm } from 'react-hook-form'

import InputField from 'components/InputField'
import { SUPER_ADMIN, VOUCHER } from 'constants/rolesConst'
import useStyles from 'views/Products/products.styles'

const BasicInfoStep = ({
  info,
  handleBack,
  handleSubmitStep,
  discountTypes,
  coupons,
  isEdit,
  sellerInfo,
}) => {
  const [discountType, setDiscountType] = useState(
    sellerInfo.role === VOUCHER ? discountTypes.find((item) => item.uniqueId === 1).id : null
  )
  const {
    register,
    handleSubmit,
    setValue,
    errors,
    trigger,
    formState: { isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues: info,
  })
  const classes = useStyles()
  const [couponsCode, setCouponsCode] = useState([])

  const onSubmit = (data) => {
    if (isEdit && info?.couponCode) {
      handleSubmitStep({
        percentLuegopago: Number(data.percentLuegopago),
        couponName: data.couponName,
        couponValue: Number(data.couponValue),
        couponCode: info.couponCode,
        discountType,
      })
    } else {
      handleSubmitStep({
        ...data,
        discountType,
        couponValue: Number(data.couponValue),
        percentLuegopago: Number(data.percentLuegopago),
      })
    }
  }

  const loadData = () => {
    if (info.discountType) {
      setDiscountType(() => info.discountType)
      trigger()
    }
    setCouponsCode(coupons.map((coupon) => coupon.couponCode))
  }

  useEffect(() => {
    if (info.discountType) {
      loadData()
    } else {
      setValue('percentLuegopago', sellerInfo.role === SUPER_ADMIN ? 100 : 0)
    }
  }, [])

  useEffect(() => {
    if (discountType && sellerInfo.role !== VOUCHER) {
      trigger('couponValue')
    }
  }, [discountType])

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputField
          label="Descripcion"
          name="couponName"
          error={errors?.couponName && errors.couponName.message}
          ref={register({ required: 'La descripción del cupón es requerido' })}
        />
        <Grid container direction="row" spacing={3}>
          <Grid item xs={sellerInfo.role === SUPER_ADMIN ? 6 : 12}>
            <InputField
              label="Código para redimir"
              name="couponCode"
              disabled={isEdit}
              onBlur={(e) => {
                let code = e.target.value
                if (code.length > 0) {
                  code = code.toUpperCase().replace(/\s/g, '')
                  setValue('couponCode', code.toUpperCase())
                  trigger('couponCode')
                }
              }}
              error={errors.couponCode && errors.couponCode.message}
              ref={register({
                validate: {
                  required: (value) => value.length > 0 || 'El código del cupón es requerido',
                  unique: (value) => {
                    if (info?.couponCode) {
                      return true
                    }
                    return !couponsCode.includes(value) || 'El código del cupón ya existe'
                  },
                  noSpecialCharacters: (value) => {
                    return (
                      /^[a-zA-Z0-9]+$/.test(value) ||
                      'El código del cupón no puede contener caracteres especiales'
                    )
                  },
                },
              })}
            />
          </Grid>
          <Grid item hidden={sellerInfo.role !== SUPER_ADMIN} xs={6}>
            <InputField
              label="Valor asumido por Luegopago"
              error={errors.percentLuegopago && errors.percentLuegopago.message}
            >
              <OutlinedInput
                name="percentLuegopago"
                endAdornment={<InputAdornment position="start">%</InputAdornment>}
                type="number"
                inputRef={register({
                  validate: {
                    required: (value) => value >= 0 || 'El valor asumido es requerido',
                    between: (value) => {
                      return (
                        (value >= 0 && value <= 100) || 'El valor asumido debe estar entre 0 y 100%'
                      )
                    },
                  },
                })}
              />
            </InputField>
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={3}>
          <Grid item xs={6}>
            <InputField label="Tipo de descuento">
              <Select
                name="discountType"
                value={discountType}
                variant="outlined"
                onChange={(event) => {
                  setDiscountType(event.target.value)
                  setValue('couponValue', null)
                }}
                disabled={sellerInfo.role === VOUCHER}
                fullWidth
              >
                {discountTypes.map((type) => (
                  <MenuItem key={type.id} value={type.id}>
                    {type.name}
                  </MenuItem>
                ))}
              </Select>
            </InputField>
          </Grid>
          <Grid item xs={6}>
            <InputField
              label="Valor de descuento"
              error={errors.couponValue && errors.couponValue.message}
            >
              <OutlinedInput
                startAdornment={
                  discountType === 3 && <InputAdornment position="start">$</InputAdornment>
                }
                name="couponValue"
                disabled={!discountType}
                placeholder={!discountType && 'Seleccione un tipo de descuento'}
                endAdornment={
                  discountType === 4 && <InputAdornment position="start">%</InputAdornment>
                }
                type="number"
                inputRef={register({
                  validate: {
                    required: (value) => value > 0 || 'El valor de descuento es requerido',
                    between: (value) => {
                      if (discountType === 3) {
                        return value > 0 || 'El valor de descuento debe ser mayor a 0'
                      }
                      return (
                        (value >= 0 && value <= 100) ||
                        'El valor de descuento debe estar entre 0 y 100%'
                      )
                    },
                  },
                })}
              />
            </InputField>
          </Grid>
        </Grid>
        <DialogActions>
          <Button disabled className={classes.btnBackgroundErrorPriceSmall} onClick={handleBack}>
            Atrás
          </Button>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            className={classes.btnNewProductsmall}
          >
            Siguiente
          </Button>
        </DialogActions>
      </form>
    </div>
  )
}

export default BasicInfoStep
