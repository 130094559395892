import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  tooltipPlacementTop: {
    fontSize: '12px',
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
    boxShadow: '-6px 6px #E8E8E8',
  },
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 410,
    height: 48,
    border: '2px solid #7A7A7A',
    borderRadius: 8,
    boxShadow: 'none',
    position: 'relative',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    fontFamily: 'Red Hat Display Regular',
  },
  helperText: {
    position: 'absolute',
    bottom: '-28px',
    left: '0px',
    fontSize: '12px',
    margin: '4px 0',
  },
  iconButton: {
    padding: 10,
    borderRadius: 8,
  },
  'label.Mui-focused': {
    color: 'green',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '16px',
    width: '100%',
    maxWidth: '600px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  btnBackgroundErrorPriceSmall: {
    padding: '0.5rem 2rem',
    borderRadius: '50px',
    border: '1px solid #6064ff',
    color: '#6064ff',
    fontWeight: '500',
    backgroundColor: '#ffffff',
    '&:hover': {
      color: '#FF40FC',
      border: '1px solid #FF40FC',
      backgroundColor: '#ffffff',
    },
  },
  btnNewProductsmall: {
    padding: '0.5rem 2rem',
    borderRadius: '50px',
    border: 'none',
    backgroundColor: '#5e44ff',
    color: 'white',
    fontWeight: '500',
    '&:hover': {
      fontWeight: 'bolder',
      backgroundColor: '#ef0bef',
      boxShadow: '-7px 7px 0px #000000',
      border: '2px solid #000000',
      borderRadius: '50px',
      opacity: '1',
    },
  },
  filterTitle: { fontSize: '26px', marginBottom: '24px' },
  filterText: {
    fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
  },
  modalButtonContainer: { marginTop: '2rem', display: 'flex', justifyContent: 'space-between' },
  soatTableHeader: { fontFamily: 'Red Hat Display Black', fontSize: 13 },
  primaryColor: {
    color: '#6064FF',
  },
  skeleton: {
    borderRadius: 5,
  },
  modalIcon: { display: 'flex', justifyContent: 'center', margin: '24px 0' },
  modalCenteredTitle: { textAlign: 'center', fontSize: '26px', marginBottom: '24px' },
  modalMaintenanceText: {
    fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
    textAlign: 'center',
  },
  paymentModalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid #f2f2ff',
  },
  paymentModalHeaderContent: {
    display: 'flex',
    gap: '1rem',
    alignItems: 'center',
    padding: '1rem',
  },
  paymentModalContent: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '1rem',
  },
  sharedPaymentModalContent: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  sharedPaymentModalTitle: {
    fontSize: '12px',
    color: '#6064ff',
  },
  sharedPaymentConcepts: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    textAlign: 'left',
    color: '#707070',
    padding: '1rem',
    width: '50%',
  },
  sharedPaymentValues: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    textAlign: 'right',
    padding: '1rem',
    width: '50%',
  },
  divider: {
    margin: '1rem 0',
  },
  paymentModalFooter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    padding: '1rem',
    gap: '0.5rem',
    fontSize: '12px',
  },
  textAlignRight: {
    textAlign: 'right',
  },
  textAlignLeft: {
    textAlign: 'left',
  },
  textBold: {
    fontWeight: 'bold',
  },
  textBlack: {
    color: '#000000',
  },
  paymentStatusChip: {
    fontSize: '12px',
    padding: '0.5rem 2rem',
    borderRadius: '50px',
  },
  lightBackground: {
    backgroundColor: '#f2f2ff',
  },
  financialDataValue: {
    fontSize: '2rem',
    fontWeight: 'bold',
  },
  financialDataSubtitle: { color: 'black', fontSize: '1rem' },
  financialDataGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
    gap: '1rem',
  },
  financialDataGridChilds: {
    padding: '1rem',
    backgroundColor: '#f2f2ff',
    borderRadius: '8px',
  },
  financialDataModalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '1rem 0',
  },
  financialDataModalTitle: {
    color: 'black',
    fontSize: '1.5rem',
    fontWeight: 'bold',
  },
  financialDataGridFull: {
    padding: '1rem',
    backgroundColor: '#f2f2ff',
    borderRadius: '8px',
    gridColumn: '1 / -1',
  },
  grayColor: {
    color: '#707070',
  },
}))

export const CustomSwitchStyles = (theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#6064FF',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#6064FF',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
})
