/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'

import Cookie from 'js-cookie'
import jwtDecode from 'jwt-decode'
import PropTypes from 'prop-types'

import { CLAIM_EMAIL, CLAIM_NAME, CLAIM_ROLE, CLAIM_USERID } from 'constants/claimsConstants'
import {
  COOKIE_ACCESS_TOKEN_NAME,
  COOKIE_NAME_ISLOGEDIN,
  COOKIE_NAME_USER,
  COOKIE_ROLE_NAME,
  COOKIE_ACCESS_SHOPIFY,
} from 'constants/cookiesConsts'
import { routeBase } from 'constants/environments.constants'
import { SELLER } from 'constants/rolesConst'
import { routesLink } from 'constants/routesConsts'
import { getReturnsCount } from 'services/Returns'
import { findRole } from 'utils/functions'
import { SUPER_ADMIN } from 'constants/rolesConst'

const AuthContext = React.createContext()
export default AuthContext

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState(null)
  const [categorylocal, setCategorylocal] = useState(false)
  const [isLoggedIn, setIsLoggedIn] = useState(null)
  const [needAddWareHouse, setNeedAddWareHouse] = useState(false)
  const [isShopify, setIsShopify] = useState(false)
  const [currentFilterOrders, setCurrentFilterOrders] = useState('Procesando')
  const [returnsCount, setReturnsCount] = useState(0)
  const [dialogAlert, setDialogAlert] = useState({
    open: false,
    title: '',
    handleClose: null,
    message: '',
    personalized: false,
    textButtonConfirm: '',
    actionConfirm: null,
    actionCancel: null,
    textButtonCancel: '',
    children: null,
    successful: false,
  })

  const persistUserData = (userData) => {
    Cookie.set(COOKIE_NAME_ISLOGEDIN, true)
    Cookie.set(COOKIE_NAME_USER, userData)
  }

  const updateReturnsCount = () => {
    if (routeBase[process.env.REACT_APP_ENVIRONMENT].hideReturns || user.role !== SUPER_ADMIN) {
      setReturnsCount(0)
    } else {
      getReturnsCount()
        .then((response) => {
          setReturnsCount(response.data)
        })
        .catch((error) => {
          setReturnsCount(0)
          console.error('Error al obtener el conteo de devoluciones:', error)
          throw error
        })
    }
  }

  const persistShopifyData = (isShopify) => {
    Cookie.set(COOKIE_ACCESS_SHOPIFY, isShopify)
  }

  const recoverUserData = () => {
    const cookieUser = Cookie.getJSON(COOKIE_NAME_USER)

    return cookieUser
      ? {
          id: cookieUser.id || '',
          name: cookieUser.name || '',
          sellerId: cookieUser.sellerId || '',
          sellerName: cookieUser.sellerName || '',
          email: cookieUser.email || '',
          picture: cookieUser.picture || null,
          role: cookieUser.role || null,
          passwordAlreadyChanged: cookieUser.passwordAlreadyChanged || null,
          isHadWarehouse: cookieUser.isHadWarehouse || null,
          isHadTermsAndConditions: cookieUser.isHadTermsAndConditions || null,
        }
      : null
  }

  const setLoggedin = (userLoggedin) => {
    persistUserData(userLoggedin)
    setUser(userLoggedin)
    setIsLoggedIn(true)
  }

  const setAccessToken = (accessToken) => {
    const decoded = jwtDecode(accessToken)
    let role = ''
    if (Array.isArray(decoded[CLAIM_ROLE])) {
      role = findRole(decoded[CLAIM_ROLE])
    } else {
      role = decoded[CLAIM_ROLE]
    }
    const userInfo = {
      id: decoded[CLAIM_USERID],
      firstName: decoded[CLAIM_NAME],
      name: decoded.UserName,
      email: decoded[CLAIM_EMAIL],
      role: role,
      sellerId: decoded.SellerId,
      sellerName: decoded.SellerName,
      picture: decoded.Logo ? decoded.Logo : null,
      passwordAlreadyChanged: decoded.PasswordAlreadyChanged,
      isHadWarehouse: decoded.IsHadWarehouse,
      isHadTermsAndConditions: decoded.IsHadTermsAndConditions,
    }
    setLoggedin(userInfo)
    Cookie.set(COOKIE_ACCESS_TOKEN_NAME, accessToken)
    Cookie.set(COOKIE_ROLE_NAME, role)
  }
  const getAccessisShopify = () => {
    return Cookie.get(COOKIE_ACCESS_SHOPIFY)
  }

  const getAccessToken = () => {
    return Cookie.get(COOKIE_ACCESS_TOKEN_NAME)
  }

  const logout = (loginUser = null) => {
    Cookie.remove(COOKIE_ACCESS_TOKEN_NAME)
    Cookie.remove(COOKIE_NAME_ISLOGEDIN)
    Cookie.remove(COOKIE_NAME_USER)
    Cookie.remove(COOKIE_ACCESS_SHOPIFY)
    if (loginUser !== 'login') {
      window.location = routesLink.login
    }
  }
  const getRoleName = () => {
    return Cookie.get(COOKIE_ROLE_NAME)
  }

  const updateCategoryLocal = (categoryBool) => {
    setCategorylocal(categoryBool)
  }

  useEffect(() => {
    const userData = recoverUserData()
    if (userData) {
      setUser(userData)
      setIsLoggedIn(true)
    } else {
      setIsLoggedIn(false)
    }
  }, [])

  // use effect when Cookie.get(COOKIE_ACCESS_TOKEN_NAME) change
  useEffect(() => {
    const accessToken = getAccessToken()
    if (isLoggedIn && accessToken) {
      setAccessToken(accessToken)
      updateReturnsCount()
    }
  }, [isLoggedIn])

  return (
    <AuthContext.Provider
      value={{
        setLoggedin,
        setAccessToken,
        categorylocal,
        getAccessToken,
        getRoleName,
        userLoggedin: user,
        isLoggedIn,
        logout,
        isShopify,
        updateCategoryLocal,
        currentFilterOrders,
        setCurrentFilterOrders,
        dialogAlert,
        setDialogAlert,
        needAddWareHouse,
        setNeedAddWareHouse,
        setIsShopify,
        persistShopifyData,
        getAccessisShopify,
        returnsCount,
        updateReturnsCount,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
AuthContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export const AuthContextConsumer = AuthContext.Consumer
